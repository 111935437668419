import produce from "immer";
import { Events } from "src/api/events";

export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";

export interface IEvents {
    list: Array<Events>;
}

const initialState: IEvents = {
    list: [],
};

export default (state = initialState, action): IEvents =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_EVENTS_SUCCESS:
                draft.list = action.payload;
                break;

            default:
                break;
        }
    });