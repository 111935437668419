import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./SideBar.scss";
import { RouteComponentProps } from "react-router";
import {
    ADMIN_USERS_ROUTE,
    ADMIN_LABS_ROUTE,
    ADMIN_POCS_ROUTE,
    ADMIN_COMPANY_ROUTE,
    ADMIN_GROUPS_ROUTE,
    ADMIN_EVENT_ROUTE,
    STORYLINES_ROUTE,
    ADMIN_VOUCHERS_ROUTE,
    ADMIN_PRESENTATIONS_ROUTE,
    ADMIN_DASHBOARD_ROUTE,
    NOTIFICATIONS_ROUTE,
} from "src/constants/appRoutes";
import usersActive from "src/assets/images/user-active.svg";
import usersInactive from "src/assets/images/user-inactive.svg";
import dashboardActive from "src/assets/images/dashboard-active.svg";
import dashboardInactive from "src/assets/images/dashboard-inactive.svg";
import labActive from "src/assets/images/lab-active.svg";
import labInactive from "src/assets/images/lab-inactive.svg";
import pocActive from "src/assets/images/pocs-active.svg"
import pocInactive from "src/assets/images/pocs-inactive.svg"
import eventActive from "src/assets/images/events_active.svg"
import eventInactive from "src/assets/images/events_inactive.svg"
import storiesActive from "src/assets/images/stories-active.svg";
import storiesInactive from "src/assets/images/stories-inactive.svg";
import presentationActive from "src/assets/images/presentation-active.svg";
import presentationInactive from "src/assets/images/presentation-inactive.svg";
import groupsActive from "src/assets/images/group-active.svg";
import groupsInactive from "src/assets/images/group-inactive.svg";
import companyActive from "src/assets/images/company-active.svg";
import companyInactive from "src/assets/images/company-inactive.svg";
import vouchersActive from "src/assets/images/voucher-active.svg";
import vouchersInactive from "src/assets/images/voucher-inactive.svg";
import notificationActive from "src/assets/images/notification-active.svg";
import notificationInactive from "src/assets/images/notification-inactive.svg";
import pureLogo from "src/assets/images/logo-icon.svg";
import purelongLogo from "src/assets/images/logo-long.svg";
import rightarrow from "src/assets/images/right-arrow-color.svg";
import { useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";


interface Route {
    title: string;
    route: string;
    activeImg: string;
    inactiveImg: string;
    activeAlt: string;
    inactiveAlt: string;
    isActive: boolean;
};

type SideBarProps = {} & RouteComponentProps;
const routesData: Route[] = [
    { title: 'Dashboard', route: ADMIN_DASHBOARD_ROUTE, activeImg: dashboardActive, inactiveImg: dashboardInactive, activeAlt: 'Dashboard-Active', inactiveAlt: 'Dashboard-Inactive', isActive: true },
    { title: 'Labs', route: ADMIN_LABS_ROUTE, activeImg: labActive, inactiveImg: labInactive, activeAlt: 'Lab-Active', inactiveAlt: 'Lab-Inactive', isActive: true },
    { title: 'Hosted POCs', route: ADMIN_POCS_ROUTE, activeImg: pocActive, inactiveImg: pocInactive, activeAlt: 'POC-Active', inactiveAlt: 'POC-Inactive', isActive: true },
    { title: 'Events', route: ADMIN_EVENT_ROUTE, activeImg: eventActive, inactiveImg: eventInactive, activeAlt: 'POC-Active', inactiveAlt: 'POC-Inactive', isActive: true },
    { title: 'Guides', route: STORYLINES_ROUTE, activeImg: storiesActive, inactiveImg: storiesInactive, activeAlt: 'Guides-Active', inactiveAlt: 'Guides-Inactive', isActive: true },
    { title: 'Presentations', route: ADMIN_PRESENTATIONS_ROUTE, activeImg: presentationActive, inactiveImg: presentationInactive, activeAlt: 'Presentations-Active', inactiveAlt: 'Presentations-Inactive', isActive: true },
    { title: 'Users', route: ADMIN_USERS_ROUTE, activeImg: usersActive, inactiveImg: usersInactive, activeAlt: 'Users-Active', inactiveAlt: 'Users-Inactive', isActive: true },
    { title: 'Groups', route: ADMIN_GROUPS_ROUTE, activeImg: groupsActive, inactiveImg: groupsInactive, activeAlt: 'Groups-Active', inactiveAlt: 'Groups-Inactive', isActive: true },
    { title: 'Companies', route: ADMIN_COMPANY_ROUTE, activeImg: companyActive, inactiveImg: companyInactive, activeAlt: 'Companies-Active', inactiveAlt: 'Companies-Inactive', isActive: true },
    { title: 'Vouchers', route: ADMIN_VOUCHERS_ROUTE, activeImg: vouchersActive, inactiveImg: vouchersInactive, activeAlt: 'Vouchers-Active', inactiveAlt: 'Vouchers-Inactive', isActive: true },
    { title: 'Notifications', route: NOTIFICATIONS_ROUTE, activeImg: notificationActive, inactiveImg: notificationInactive, activeAlt: 'Notifications-Active', inactiveAlt: 'Notifications-Inactive', isActive: true }
]

const SideBar: React.FC<SideBarProps> = ({ match, ...props }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true)
    const [sideBarData, setSideBarData] = useState(routesData);
    const loggedUser = useSelector((state: RootState) => (state.user));


    useEffect(() => {
        if (loggedUser) {
            routesData[routesData.findIndex((x: Route) => x.title === 'Hosted POCs')].isActive = loggedUser.isPOCAdmin;
            setSideBarData(prevData =>
                prevData.map(item =>
                    item.title === 'Hosted POCs' ? { ...item, isActive: loggedUser.isPOCAdmin } : item
                )
            );
        }
    }, [loggedUser])

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return <>
        <div className={`sidebar_admin-wrapper ${isSidebarOpen ? 'open' : ''}`} id="sidebar-admin" >
            <div className="sidebar_admin-branding" onClick={toggleSidebar}>
                <img src={pureLogo} alt="pure-logo" />
                <img className="long-logo" src={purelongLogo} alt="pure_storage_logo" />
                <img src={rightarrow} alt="right-arrow" />
            </div>

            <div className="sidebar_admin-nav">
                {sideBarData.map((item) => (
                    item.isActive &&
                    <div className={`sidebar_admin-item ${props.location.pathname.includes(
                        `${match.url}${item.route}`) ? 'active' : ''}`}>
                        <NavLink
                            to={`${match.url}${item.route}`}
                            className="sidebar_admin-item-link"
                            activeClassName="itemnav-active"
                        >
                            {props.location.pathname.includes(
                                `${match.url}${item.route}`
                            ) ? (
                                <img className={`sidebar_admin-item-icon ${item.title}`} src={item.activeImg} alt={item.activeAlt} />
                            ) : (
                                <img className={`sidebar_admin-item-icon ${item.title}`} src={item.inactiveImg} alt={item.inactiveAlt} />
                            )}
                            <span className="sidebar_admin-item-text">{item.title}</span>
                        </NavLink>
                    </div>
                ))
                }

            </div>
        </div>
    </>

}

export default SideBar;