import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useLocation } from "react-router-dom";
import { startVmPollingAction, stopVmPollingAction } from "src/state/ui/actions";
import { extendLabTime, getLabVmURL, getVmStatus, } from "src/api/lab";
import usePolling from "src/hooks/usePolling";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import "./Console.scss";
import useWindowSize from "src/hooks/useWindowSize";
import PreviewSection from "src/containers/admin/StoryLine/PreviewSection";
import leftArrowIcon from "src/assets/images/left-arrow.svg";
import { RootState } from "src/state/rootReducer";
import AppHeader from "src/components/AppHeader";
import { isPromiseReturned } from "src/utils/common";
import NewWindow from "react-new-window";
import PureModal from "src/components/shared/PureModal";
import { DropdownOption } from "src/components/AppHeader/AppHeader";
import useLaunchedLab from "src/hooks/useLaunchedLab";
import { WAITING_MSG_CONSOLE, WAITING_MSG_CONSOLE_EXTRA } from "src/constants/pocs";

type ConsoleProps = {} & RouteComponentProps;

const Console: React.FC<ConsoleProps> = (props) => {
  const [width, height] = useWindowSize();
  const { pathname, search } = useLocation();

  const [showLoader, setShowLoader] = useState(false);
  const [killPollingInterval, setKillPollingInterval] = useState(false);

  const urlParams = new URLSearchParams(search);
  const labId = urlParams.get("id");
  const consoleVmName = urlParams.get("console_vm");
  const consoleEle = document.getElementById("console");
  const [isPortalFullWidth, setIsPortalFullWidth] = React.useState(false);

  const [popupWarning, setPopWarning] = useState(false);
  const togglePopupWarning = () => setPopWarning(!popupWarning);

  const dispatch = useDispatch();

  const consoleRef = React.useRef<HTMLDivElement | null>(null);

  const vmPollingStatus: boolean = useSelector(
    (state: RootState) => state.ui.vmPollingStatus
  );

  const isFullScreen =
    window.innerWidth === window.screen.width &&
    window.innerHeight === window.screen.height;

  const [labDetails, setLabDetails] = useState<any>(null);

  const {
    loadVCDLab,
    fullScreen,
    resizeLab,
    sendKeys,
    getLabDetails,
    wmksConnectionState,
    setWmksConnectionState,
    destroyWmks
  } = useLaunchedLab(labId, "console", vmPollingStatus, labDetails, consoleVmName);

  const [openWidget, setWidgetState] = useState(true);
  const toggleWidgetState = () => {
    if (widgetPortalState) {
      setWidgetPortalState(!widgetPortalState);
    }
    setWidgetState(!openWidget);
  };

  const [widgetPortalState, setWidgetPortalState] = React.useState(false);
  const toggleWidgetPortalState = () => {
    toggleWidgetState();
    setWidgetPortalState(!widgetPortalState);
  };

  const onPollingStart = () => {
    setShowLoader(true);
    dispatch(startVmPollingAction());
  };

  const stopPolling = () => {
    setShowLoader(false);
    setKillPollingInterval(true);
    dispatch(stopVmPollingAction());
  };

  const polling = async () => {
    const data = await getVmStatus(labId);
    if (!data || (data && data.state === "running")) {
      stopPolling();
    }
  };

  let apiInterval
  const getLab = async () => {
    const labDetails = await getLabDetails()
    setLabDetails(labDetails);
    if (labDetails?.lab?.labProviderType !== "SKYTAP") {
      loadVCDLab(labDetails)
    }

    return labDetails;
  };
  usePolling(onPollingStart, polling, 3000, killPollingInterval);

  let calledResizeTimes = 1;
  let resizeOnLaunchInterval: any = null

  const resizeOnLaunch = async (consoleUrl) => {
    if (calledResizeTimes % 5 === 0) {
      const consoleEle = document.getElementById("console");
      extendLabTime(
        labId,
        consoleUrl,
        consoleEle?.clientWidth || 1024,
        consoleEle?.clientHeight || 579,
        true,
        false
      );
    }
    ++calledResizeTimes;
    if (calledResizeTimes > 30) {
      clearInterval(resizeOnLaunchInterval);
    }
  };

  let labDetailsPromise
  const recheckForConsoleUrl = async () => {
    const data: any = await isPromiseReturned(labDetailsPromise)
    if (data.status === 'fulfilled') {
      const details = data?.value
      if (details?.consoleUrl && apiInterval) {
        clearInterval(apiInterval)
      } else {
        labDetailsPromise = getLab()
      }
    } else if (data.status === 'rejected') {
      labDetailsPromise = getLab()
    }
  }

  useEffect(() => {
    getLab()
  }, [])

  useEffect(() => {
    if (!vmPollingStatus && !!labDetails?.lab?.id && !pathname.includes("presentation")) {
      getLab().then(lab => {
        if (labDetails?.lab?.id && !lab?.consoleUrl) {
          labDetailsPromise = getLab()
          apiInterval = setInterval(() => recheckForConsoleUrl(), 3000)
        }
        if (!showLoader && labDetails?.lab?.labProviderType === "SKYTAP") {
          resizeOnLaunchInterval = setInterval(() => resizeOnLaunch(lab?.consoleUrl), 1000)
        }
      })
    }

    return () => {
      if (resizeOnLaunchInterval) {
        clearInterval(resizeOnLaunchInterval)
      }

      if (apiInterval) {
        clearInterval(apiInterval)
      }
    }
  }, [vmPollingStatus, labDetails?.lab?.id]);

  useEffect(() => {
    resizeLab(
      consoleEle?.clientWidth || width,
      consoleEle?.clientHeight || height,
    );
  }, [width, height, openWidget]);

  useEffect(() => {
    const handleFullScreen = (event) => {
      const keyCode = event.keyCode || event.which;
      // key F11
      if (keyCode === 122) {
        event.preventDefault();
        event.stopPropagation();
        fullScreen();
      }
    };

    document.addEventListener("keydown", handleFullScreen);
    return () => {
      document.removeEventListener("keydown", handleFullScreen);
    };
  }, []);

  const handlePortalOpen = (portalWindow) => {
    // on MacOS, portalWindow.outerWidth comes out to be 427 without using setTimeout which is invalid
    // because on MacOS, first window open with width 427 and than with > 427
    setTimeout(() => {
      setIsPortalFullWidth(portalWindow.outerWidth > 427);
    }, 100);
  };

  const switchLabVM = async (vm: DropdownOption) => {
    if (labId && vm?.value) {
      const data = await getLabVmURL(labId, vm.value);
      setLabDetails(data);
    }
  };

  const labVMOptions = labDetails?.lab?.labProviderConfig?.consoleVms?.map(
    (vm) => ({
      label: vm.name,
      value: vm.name,
    })
  );

  let consoleClicked = React.useRef(0)

  const welcomeBackToConsole = () => {
    if (!showLoader && labDetails?.lab?.labProviderType !== "SKYTAP") {
      if (consoleClicked.current === 0 &&
        (pathname.includes("presentation") || wmksConnectionState === 'disconnected')
      ) {
        ++consoleClicked.current
        getLab()
      }
    }
  }

  const handlePresentationClick = (newState: string) => {
    setWmksConnectionState(newState)
    consoleClicked.current = 0
  }

  return (
    <div ref={consoleRef} className="console-container">
      <PureModal
        title="Alert"
        primaryBtnLabel="Ok"
        showModal={popupWarning}
        primaryBtnAction={togglePopupWarning}
        closeModal={togglePopupWarning}
        className="highest-ZIndex preview-section-modal"
      >
        <span>
          Your browser permissions restricts to pop out the guide window. Please
          update the browser settings then try again.
        </span>
      </PureModal>
      <AppHeader
        lab={labDetails}
        isFullScreen={isFullScreen}
        labVMOptions={labVMOptions}
        switchLabVm={switchLabVM}
        onToggleFullScreen={fullScreen}
        handleConsoleClick={welcomeBackToConsole}
        handleKeys={sendKeys}
        handlePresentationClick={handlePresentationClick}
        onDeleteLab={destroyWmks}
      />
      {pathname.includes("presentation") ? (
        <iframe
          className="console-presentation"
          id="console"
          title="googleSlides"
          width="100%"
          src={labDetails?.lab?.presentation?.webViewLink}
          frameBorder="0"
          allowFullScreen={true}
        />
      ) : (
        <div style={{ height: "calc(100% - 3.8rem)", width: "100%" }}>

          {showLoader ? (
            <div className="loader">
              <Spinner
                animation="border"
                style={{ color: "var(--color-primary)" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>{labDetails && <>

                <span style={{ fontSize: "1.3rem", color: "#cccccc" }}>
                  {labDetails?.lab.labProviderType.includes('HOSTEDPOC') ? labDetails?.deploymentState?.toLowerCase() === 'ready' ? 'Connecting to your Console' : WAITING_MSG_CONSOLE : 'Connecting to your Lab'}
                </span>
                {labDetails?.lab.labProviderType.includes('HOSTEDPOC') ?
                  <span style={{ color: "#cccccc", fontSize: "1.3rem" }}>{labDetails?.deploymentState?.toLowerCase() === 'ready' ? '' : WAITING_MSG_CONSOLE_EXTRA}</span> :
                  <span style={{ color: "#cccccc" }}>
                    This may take a minute or two…
                  </span>}
              </>
              }
            </div>
          ) : (
            <div className="console-widget d-flex flex-row justify-content-end">
              {labDetails?.lab?.labProviderType !== "SKYTAP" ? (
                <div id="console" className="console console-vcd" />
              ) : (
                <iframe
                  title="Console"
                  src={labDetails?.consoleUrl}
                  id="console"
                  className="console"
                />
              )}
              {openWidget ? (
                <div className="console-widget-opened">
                  <PreviewSection
                    includeMinimise={true}
                    widgetPortalState={widgetPortalState}
                    onTogglePortal={toggleWidgetPortalState}
                    toggleWidgetState={toggleWidgetState}
                    storyLine={labDetails?.lab?.storyline}
                    height="calc(100vh - 64px)"
                  />
                </div>
              ) : (
                <div
                  className="console-widget-closed cursor-pointer"
                  onClick={toggleWidgetState}
                >
                  <div className="half-circle"> </div>
                  <div className="lines">
                    <img src={leftArrowIcon} alt="left-arrow-widget" />
                    <b> Guide </b>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {widgetPortalState && (
        <NewWindow
          features={{
            width: 427,
            height: window.screen.height - 150,
            overflow: "hidden",
            rel: "noopener",
            location: 0,
          }}
          name={labId || "_blank"}
          onBlock={togglePopupWarning}
          onOpen={handlePortalOpen}
        >
          <div
            className="portal-window"
            style={{
              width: isPortalFullWidth ? 427 : "unset",
            }}
          >
            <PreviewSection
              includeMinimise={true}
              toggleWidgetState={toggleWidgetState}
              widgetPortalState={widgetPortalState}
              onTogglePortal={toggleWidgetPortalState}
              storyLine={labDetails?.lab?.storyline}
              height="100vh"
            />
          </div>
        </NewWindow>
      )}
    </div>
  );
};

export default Console;
