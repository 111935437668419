// validationSchemas.js
import { userTypesMap } from 'src/constants/users';
import * as Yup from 'yup';

// export const oppIdValidationSchema = Yup.string()
//     .required('Opportunity ID is required')
//     .when('oppId', {
//         is: (value) => /^https:\/\/purestorage\.lightning\.force\.com\/lightning\/r\/Opportunity\//.test(value), // check if it's a URL
//         then: Yup.string()
//             .matches(
//                 /^https:\/\/purestorage\.lightning\.force\.com\/lightning\/r\/Opportunity\/([a-zA-Z0-9]{18})$/,
//                 'Opportunity ID must begin with "https://purestorage.lightning.force.com/lightning/r/Opportunity/" followed by a valid ID.'
//             )
//             .test('valid-extracted-id', 'Extracted Opportunity ID is invalid', (value) => {
//                 const match = value?.match(
//                     /^https:\/\/purestorage\.lightning\.force\.com\/lightning\/r\/Opportunity\/([a-zA-Z0-9]{18})$/
//                 );
//                 if (match) {
//                     const extractedOppId = match[1];
//                     const oppIdRegex = /^006[a-zA-Z0-9]{15}$/;
//                     return oppIdRegex.test(extractedOppId);
//                 }
//                 return false;
//             }),
//         otherwise: Yup.string()
//             .matches(
//                 /^006[a-zA-Z0-9]{15}$/,
//                 'Opportunity ID must be exactly 18 characters long, start with "006", and contain only alphanumeric characters.'
//             ),
//     });
const urlPattern = /^https:\/\/purestorage\.lightning\.force\.com\/lightning\/r\/Opportunity\/([^\/]+)(\/view)?$/;

const oppIdValidationSchema =(userType?)=> Yup.string()
.when([], {
    is: () => { return userType === userTypesMap.corporate || userType === userTypesMap.partner  || (!userType && userType !== userTypesMap.adhoc)  }, 
    then: Yup.string().required('Opportunity ID is required'), // Required if the condition is true
    otherwise: Yup.string().notRequired(), // Not required if user.type === "corporate"
  })
   // .required('Opportunity ID is required')
    .test('is-valid-opportunity', 'Please enter a valid Opportunity Id / Link', (value) => {
        if(userType === userTypesMap.adhoc || !value){
            return true;
        }
        // Check if the value is a URL starting with 'https://purestorage.lightning.force.com/lightning/r/Opportunity'
        if (value && value.startsWith('https://purestorage.lightning.force.com/lightning/r/Opportunity/')) {
            const match = value.match(urlPattern);
            if (match) {
                // Extract Opp ID from the URL
                const extractedOppId = match[1];

                // Validate that extracted Opp ID is 18 chars long and starts with '006'
                return /^[A-Za-z0-9]{18}$/.test(extractedOppId) && extractedOppId.startsWith('006');
            }

            return false;
        }

        // If it's not a URL, check if it's an 18-character alphanumeric string starting with '006'
        if (value && /^[A-Za-z0-9]{18}$/.test(value)) {
            return value.startsWith('006');
        }
       
        return false; // Return false if none of the conditions are met
        
    });
export default oppIdValidationSchema;

const extractOppIdFromUrl = (value) => {
    const match = value.match(urlPattern);

    if (match) {
        // Return the extracted Opp ID (18 characters)
        return match[1];
    }

    return value;
};
export { extractOppIdFromUrl };





// const oppIdValidationSchema = Yup.string()
//   .required('Opp ID is required')
//   .test('is-url-or-id', 'Invalid Opp ID format', (value) => {
//     // Check if value is a URL
//     if (value && value.startsWith('https://')) {
//       const urlPattern = /^https:\/\/purestorage\.lightning\.force\.com\/lightning\/r\/([^\/]+)$/;
//       const match = value.match(urlPattern);

//       if (match) {
//         // Extract Opp ID from URL and validate it
//         const extractedOppId = match[1];
//         return /^[A-Za-z0-9]{18}$/.test(extractedOppId) && extractedOppId.startsWith('006');
//       } else {
//         return false;
//       }
//     }
    
//     // If it's not a URL, check if it's an 18 character string
//     if (value && /^[A-Za-z0-9]{18}$/.test(value)) {
//       return value.startsWith('006');
//     }
    
//     return false;
//   })
//   .test('is-valid-length', 'Opp ID must be exactly 18 characters long', (value) => {
//     if (value && value.length === 18) {
//       return true;
//     }
//     return false;
//   })
//   .test('no-special-chars', 'Opp ID cannot contain special characters or punctuation', (value) => {
//     if (value && /^[A-Za-z0-9]{18}$/.test(value)) {
//       return true;
//     }
//     return false;
//   });

// export default oppIdValidationSchema;