import React from "react";
import Layout from "src/components/Layout";
import AuthLayout from "src/containers/Auth/AuthLayout";
import { Route, Switch, Redirect } from "react-router-dom";
import Labs from "./components/Labs";
import {
  LABS_ROUTE,
  HOME_ROUTE,
  VOUCHERS_ROUTE,
  ADMIN_ROUTE,
  AUTH_LAYOUT_ROUTE,
  CONSOLE_ROUTE,
  SUBMIT_TICKET_ROUTE,
  VERIFY_USER_ROUTE,
  SET_USER_PASSWORD_ROUTE,
  VOUCHER_CODE_ROUTE,
  PRESENTATION_ROUTE,
  PAGE_NOT_FOUND_ROUTE,
  TNC_ROUTE,
  OKTA_UNAUTHORIZED_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  USER_STORYLINE_ROUTE,
  USER_MODE_STORYLINE_ROUTE, USERS_ROUTE,
  ACCESSDENIED_ROUTE,
  EVENTS_ROUTE
} from "src/constants/appRoutes";
import OktaUserLandingPage from "src/components/OktaUserLandingPage";
import Console from "src/components/Console";
import AppHeader from "src/components/AppHeader";
import AdminLayout from "src/containers/admin/AdminLayout";
import SubmitTicket from "src/containers/user/SubmitTicket";
import UserVouchers from "src/containers/user/UserVouchers";
import LabVoucher from "src/containers/user/LabVoucher";
import SetPassword from "src/components/SetPassword";
import PageNotFound from "src/components/ui/PageNotFound";
import TNCViewer from "src/components/TNCViewer";
import OktaUnauthorizedUser from "src/components/ui/OktaUnauthorizedUser";
import ChangePassword from "src/components/ChangePassword/ChangePassword";
import Dashboard from "src/containers/Dashboard";
import StoryLineList from "src/containers/admin/StoryLine/StoryLineList";
import ManageStoryLine from "src/containers/admin/StoryLine/ManageStoryLine";
import AccessDenied from "./components/AccessDenied";
import EventsList from "./containers/admin/Events";

type routesProps = {};

const Routes: React.FC<routesProps> = (props) => {

  return (
    <Switch>
      {/* Routes for non authenticated users */}
      <Route path={AUTH_LAYOUT_ROUTE} component={AuthLayout} />
      <Route path={HOME_ROUTE} component={OktaUserLandingPage} exact />
      <Route path={USERS_ROUTE} component={OktaUserLandingPage} exact />
      <Route
        path={`${SET_USER_PASSWORD_ROUTE}/:uid/:token`}
        component={SetPassword}
      />
      <Route path={VERIFY_USER_ROUTE} component={SetPassword} />
      <Route path={VOUCHER_CODE_ROUTE} component={LabVoucher} />
      <Route path={PAGE_NOT_FOUND_ROUTE} component={PageNotFound} exact />
      <Route path={TNC_ROUTE} component={TNCViewer} exact />
      <Route
        path={OKTA_UNAUTHORIZED_ROUTE}
        component={OktaUnauthorizedUser}
        exact
      />
      <Route path={ACCESSDENIED_ROUTE} component={AccessDenied} />
      {/* Routes for authenticated users */}
      {/* Admin Route entry point */}
      <Route path={ADMIN_ROUTE} component={AdminLayout} />
      <Layout path={LABS_ROUTE} header={AppHeader} component={Labs} exact />
      <Layout path={EVENTS_ROUTE} header={AppHeader} component={EventsList} exact />
      <Layout
        path={VOUCHERS_ROUTE}
        header={AppHeader}
        component={UserVouchers}
        exact
      />
      <Layout path={CONSOLE_ROUTE} component={Console} exact />
      <Layout path={PRESENTATION_ROUTE} component={Console} exact />
      <Layout
        header={AppHeader}
        path={CHANGE_PASSWORD_ROUTE}
        component={ChangePassword}
        exact
      />
      <Layout
        path={USER_STORYLINE_ROUTE}
        header={AppHeader}
        component={StoryLineList}
        exact
      />
      <Layout
        path={USER_MODE_STORYLINE_ROUTE}
        header={AppHeader}
        component={ManageStoryLine}
        exact
      />
      <Layout
        path={SUBMIT_TICKET_ROUTE}
        header={AppHeader}
        component={SubmitTicket}
        exact
      />
      <Layout
        path={ADMIN_DASHBOARD_ROUTE}
        header={AppHeader}
        component={Dashboard}
        exact
      />
      <Redirect to={LABS_ROUTE} />
    </Switch>
  );
};

export default Routes;
