import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ReactComponent as ActiveLab } from "src/assets/images/active-lab.svg";
import { ReactComponent as AllLab } from "src/assets/images/all-lab.svg";
import { ReactComponent as AllLabActiveTab } from "src/assets/images/all-labs-active-tab.svg";
import { ReactComponent as ActiveLabActiveTab } from "src/assets/images/active-labs-active-tab.svg";
import { ReactComponent as POCLabActiveTab } from "src/assets/images/pocsLab-active.svg";
import { ReactComponent as POCLab } from "src/assets/images/pocsLab-inactive.svg";
import "./Labs.scss";
import SearchBox from "../shared/SearchBox";
import LabCard from "src/containers/LabCard";
import NoSearchResultFound from "../ui/NoSearchResultFound";
import { useDispatch, useSelector } from "react-redux";
import { setSidebar } from "src/state/sidebar/actions";
import { RootState } from "src/state/rootReducer";
import { RouteComponentProps } from "react-router-dom";
import useLabApprovalPolling from "./useLabApprovalPolling";
import PureModal from "src/components/shared/PureModal";
import DOMPurify from 'dompurify';
import { getUserLabs } from "src/state/userLabs/actions";
import { fetchUserLabs } from "src/api/lab";
import { toCamelCaseKeys } from "src/helpers/common";
import { mapUserLabs } from "src/helpers/labs";

type LabsProps = {} & RouteComponentProps;

const Labs: React.FC<LabsProps> = (props) => {
  const labs = useSelector((state: RootState) => state.userLabs.list);
  const [searchVal, setSearchVal] = useState("");
  const [showActivePOCTab, setShowActivePOCTab] = useState(false)
  const [tabKey, setTabKey] = useState("");
  const dispatch = useDispatch();
  const sidebar = useSelector((state: RootState) => state.sidebar);

  const [requestDescription, setRequestDescription] = React.useState("");
  const resetRequestDescription = () => setRequestDescription("");

  const getLabs = async (subdescription?: string) => {
    if (subdescription) {
      const cleanDescription = DOMPurify.sanitize(subdescription)
      setRequestDescription(cleanDescription);
    }

    let data = await fetchUserLabs()
    data = toCamelCaseKeys(data);
    const labs = [
      ...mapUserLabs(data.activeLabs),
      ...mapUserLabs(data.assignedLabs),
    ]
    dispatch(getUserLabs(labs))

    if (data?.activeLabs?.length > 0) {
      let activePocLab = data?.activeLabs.filter(x => x.lab.labProviderType.toLowerCase().includes('hostedpoc'));
      setShowActivePOCTab(activePocLab.length > 0 ? true : false);
      if (activePocLab?.length == data?.activeLabs?.length) {
        handleTabChange("hostedPOC");
      }
      else {
        handleTabChange("activeLabs");
      }

      if (activePocLab?.length > 0 && (data?.activeLabs?.length - activePocLab?.length) > 0) {
        handleTabChange("allLabs");
      }
    } else {
      handleTabChange("allLabs");
    }
  };

  useEffect(() => {
    sidebar.sidebarOpen && dispatch(setSidebar());
    getLabs();
  }, []);

  const handleSearch = (value) => {
    setSearchVal(value);
  };
  const handleTabChange = (key) => {
    setTabKey(key);
  };

  const activeLabsGIDs = labs
    .filter(
      (lab) => lab.isActive && lab?.lab?.labProviderConfig?.isApprovalRequired
    )
    .map((lab) => lab.gid);

  const { approvalStatus } = useLabApprovalPolling(activeLabsGIDs, 600000);

  const ShowLabs = ({ labType }) => {
    console.log('labType', labType, tabKey)
    const formatName = (lab) =>
      (lab.name || lab.lab.name)
        .toLowerCase()
        .includes(searchVal?.toLowerCase());

    const mappedLabs = labs
      .filter((lab) =>
        labType === "activeLabs"
          ? lab.isActive && (!lab.labProviderType?.includes('HOSTEDPOC') && !lab?.lab?.labProviderType?.includes('HOSTEDPOC')) && formatName(lab)
          : labType === "allLabs" ? (!lab.labProviderType?.includes('HOSTEDPOC') && !lab?.lab?.labProviderType?.includes('HOSTEDPOC')) && formatName(lab)
            : lab.isActive && (lab.labProviderType?.includes('HOSTEDPOC') || lab?.lab?.labProviderType?.includes('HOSTEDPOC')) && formatName(lab)
      )
      .map((lab, id) => (
        <LabCard
          key={id}
          activeTab={labType}
          data={lab}
          fetchLabs={getLabs}
          approvalStatus={approvalStatus[lab?.gid]}
        />
      ));

    return mappedLabs.length ? (
      <div className="card-container mt-4">{mappedLabs}</div>
    ) : (
      <NoSearchResultFound
        message={labType === "activeLabs" ? "No Active Labs" : "No Labs Found"}
        errImgSrc={
          labType === "activeLabs" ? "no-active-lab.svg" : "no-my-lab.svg"
        }
      />
    );
  };

  return (
    <div className="d-flex mb-5 flex-grow-1">
      <PureModal
        centered
        showModal={requestDescription !== ""}
        title="Approval Popup Text"
        closeModal={resetRequestDescription}
        primaryBtnLabel="Ok"
        primaryBtnAction={resetRequestDescription}
        className="highest-ZIndex dynamic-content-modal"
      >
        <div dangerouslySetInnerHTML={{ __html: requestDescription }} />
      </PureModal>
      <div className="lab-card d-flex flex-column flex-shrink-1 flex-grow-1">
        <h5 className="ml-1 mb-5 mt-4">
          <b>Labs</b>
        </h5>
        <div className="d-flex justify-content-end lab-card-outer-section">
          <div className="users-section-search d-flex align-items-center">
            <SearchBox
              updateSearchQuery={handleSearch}
              placeholder="Search by lab name"
            />
          </div>
        </div>
        <Tabs
          activeKey={tabKey}
          transition={false}
          id="userLabs"
          onSelect={handleTabChange}
        >
          <Tab
            eventKey="activeLabs"
            title={
              <div className="d-flex align-items-center">
                {tabKey === "activeLabs" ? (
                  <ActiveLabActiveTab height="20" width="20" />
                ) : (
                  <ActiveLab height="20" width="20" />
                )}
                <div className="ml-1"> Active Labs</div>
              </div>
            }
          >
            {tabKey === "activeLabs" && <ShowLabs labType="activeLabs" />}
          </Tab>
          <Tab
            eventKey="allLabs"
            title={
              <div className="d-flex align-items-center">
                {tabKey === "allLabs" ? (
                  <AllLabActiveTab height="20" width="20" />
                ) : (
                  <AllLab height="20" width="20" />
                )}
                <div className="ml-1"> All Labs</div>
              </div>
            }
          >
            {tabKey === "allLabs" && <ShowLabs labType="allLabs" />}
          </Tab>
          {showActivePOCTab &&
            <Tab
              eventKey="hostedPOC"
              title={
                <div className="d-flex align-items-center">
                  {tabKey === "hostedPOC" ? (
                    <POCLabActiveTab height="20" width="20" />
                  ) : (
                    <POCLab height="20" width="20" />
                  )}
                  <div className="ml-1">Active POCs</div>
                </div>
              }
            >
              {tabKey === "hostedPOC" && <ShowLabs labType="hostedPOC" />}
            </Tab>
          }
        </Tabs>
      </div>
    </div>
  );
};

export default Labs;
