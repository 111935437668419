import React, { useState, useEffect } from "react";
import "./GroupUsers.scss";
import Table from "src/components/shared/Table";
import { ReactComponent as AddIcon } from "src/assets/images/add.svg";
import { getGroupUserColumns } from "./groupUserColumns";
import { IGroup } from "src/state/groups/reducer";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import {
	deleteGroupUserAction,
	getGroupDetails,
} from "src/state/groups/actions";
import { deleteGroupUserById } from "src/api/groups";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { Button } from "react-bootstrap";
import SearchBox from "src/components/shared/SearchBox";
import ManageGroupUser from "src/containers/admin/Groups/ManageGroupUser";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";
import { RouteComponentProps, Link } from "react-router-dom";
import WarningModal from "src/components/shared/WarningModal";
import { getUser } from "src/state/user/actions";

type GroupUserProps = {} & RouteComponentProps<any>;

const GroupUsers: React.FC<GroupUserProps> = (props) => {
	const newGroup = { name: "", roleId: 0 };
	const newUser = { name: "", id: "" };
	const [selectedGroupDetails, setSelectedGroupDetails] = useState(newGroup);
	const groupId = props.match.params.groupId;
	const [filteredGroupUsers, setFilteredGroups] = useState([]);
	const [searchBy, setSearchBy] = useState("");
	const [selectedUser, setSelectedUser] = useState(newUser);
	const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
	const [showManageGroupUserModal, setShowManageGroupUserModal] = useState(
		false
	);
	const dispatch = useDispatch();
	const loaderCount = useSelector((state: RootState) => state.ui.loaderCount);
	const isLoading = loaderCount > 0;

	useEffect(() => {
		dispatch(getGroupDetails(groupId));
	}, []);

	const groupDetail: IGroup = useSelector(
		(state: RootState) => state.groups.groupDetail
	);

	const handleSearchByChange = (updatedSearchQuery) => {
		setSearchBy(updatedSearchQuery);
		filterGroupUsers(groupDetail.users, updatedSearchQuery);
	};

	const filterGroupUsers = (groupUsers, searchQuery) => {
		let filteredGroupUsers = groupUsers.filter((user) => {
			if (!searchQuery) return true;
			const name = user.name && user.name.toLowerCase();
			const email = user.email && user.email.toLowerCase();
			const query = searchQuery && searchQuery.toLowerCase();
			return (name && name.includes(query)) || (email && email.includes(query));
		});
		setFilteredGroups(filteredGroupUsers);
	};

	useEffect(() => {
		groupDetail.users && filterGroupUsers(groupDetail.users, searchBy);
	}, [groupDetail.users]);

	const displayAddGroupUserModal = () => {
		const group = { name: groupDetail.name, roleId: groupDetail.role };

		setSelectedGroupDetails(group);
		setShowManageGroupUserModal(true);
	};

	const displayDeleteUserModal = (user) => {
		const { name, id } = user;
		setSelectedUser({ name, id });
		setShowDeleteWarningModal(true);
	};

	const closeDeleteGroupModal = () => {
		setSelectedUser(newUser);
		setShowDeleteWarningModal(false);
	};

	const groupUserColumns = getGroupUserColumns(displayDeleteUserModal);

	const deleteUser = async () => {
		const response = await deleteGroupUserById(groupId, selectedUser.id);
		if (!!response && !response.isAxiosError) {
			dispatch(deleteGroupUserAction(selectedUser.id));
			closeDeleteGroupModal();
		}
		//add condition to update only for poc admin group
		dispatch(getUser());


	};

	return (
		<div className="group-users-section d-flex flex-column flex-grow-1">
			{showDeleteWarningModal && (
				<WarningModal
					title="Warning"
					onCancelAction={closeDeleteGroupModal}
					onConfirmAction={deleteUser}
				>
					<div>
						<div>Are you sure ?</div>
						<div>
							User <b>{selectedUser.name}</b> will be removed from{" "}
							{groupDetail.name} ?
						</div>
					</div>
				</WarningModal>
			)}
			<div className="d-flex flex-row justify-content-sm-between mb-2">
				<div className={`group-users-section-header`}>
					<span>
						<b>
							<Link className="group-user-header" to="/admin/groups">
								Groups
							</Link>{" "}
						</b>
						<RightArrow /> <span className="h5">{groupDetail.name}</span>
					</span>
				</div>
				{showManageGroupUserModal && (
					<ManageGroupUser
						setShowModal={setShowManageGroupUserModal}
						selectedGroupId={groupId}
						selectedGroupsDetails={selectedGroupDetails}
						isGroupUser={true}
					/>
				)}

				<div className="d-flex justify-content-end ">
					<div className="group-users-section-search d-flex align-items-center">
						<SearchBox
							updateSearchQuery={handleSearchByChange}
							placeholder="Search by name or email"
						/>
					</div>
					{filteredGroupUsers.length ? (
						<div className={`align-self-end ml-2 d-inline`}>
							<Button
								type="submit"
								size="sm"
								variant="primary"
								className="group-users-section-add"
								onClick={displayAddGroupUserModal}
							>
								<div className="d-flex align-items-center pl-2 pr-2">
									<AddIcon height="10" width="10" />
									<span className="ml-2"> Add User </span>
								</div>
							</Button>
						</div>
					) : null}
				</div>
			</div>
			{filteredGroupUsers.length ? (
				<Table
					searchBy={searchBy}
					columns={groupUserColumns}
					data={filteredGroupUsers}
				/>
			) : !!searchBy ? (
				<NoSearchResultFound
					message="Your search was not successful!"
					detail="Try some relevant name or email"
					errImgSrc="no-users.svg"
				/>
			) : (
				!isLoading && (
					<>
						<NoSearchResultFound
							message="No Users Added!"
							detail="Click below to add users in this group"
							errImgSrc="no-users-added.svg"
						/>
						<Button
							type="submit"
							size="sm"
							variant="primary"
							className="group-users-section-add no-result-add-btn"
							onClick={displayAddGroupUserModal}
						>
							<div className="d-flex align-items-center pl-2 pr-2">
								<AddIcon height="10" width="10" />
								<span className="ml-2"> Add User </span>
							</div>
						</Button>
					</>
				)
			)}
		</div>
	);
};

export default GroupUsers;
