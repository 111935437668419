import Api from "src/helpers/request";

export const getEventDetailsEndpoint = "/events";


export interface Timezone {
    originalTimeZone: string,
    abbreviation: string,
    formatedLabel: string
}
export interface Events {
    id: number
    owner: Owner
    manager: Manager
    created_on: string
    updated_on: string
    name: string
    code: string
    description: string
    start_date: string
    start_time: string
    end_date: string
    end_time: string
    utc_date_time: UtcDateTime
    is_active: boolean
}

export interface Owner {
    id: string
    email: string
    name: string
}

export interface Manager {
    id: string
    email: string
    name: string
}

export interface UtcDateTime {
    end_datetime: string
    start_datetime: string
    actual_timezone: string
}


/* API to create a new Events */
export const createEvents = (data) => {
    return Api.call(
        { url: `${getEventDetailsEndpoint}`, method: "POST", data },
        { showLoader: true }
    );
};

/* API to update a Events */
export const updateEvents = (data, id) => {
    return Api.call(
        { url: `${getEventDetailsEndpoint}/${id}`, method: "PUT", data },
        { showLoader: true }
    );
};



/* API to fetch labs list */
export const fetchEvents = (metaConfig = { showLoader: true }) => {
    return Api.call(
        {
            url: `${getEventDetailsEndpoint}`,
            method: "GET",
        },
        metaConfig
    );
};


/* API to fetch labs list */
export const deleteEventById = (id) => {
    return Api.call(
        {
            url: `${getEventDetailsEndpoint}/${id}`,
            method: "DELETE",
        },
        { showLoader: true }
    );
};
