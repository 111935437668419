
import React, { useEffect, useState } from "react";
import { formatDate } from "../PocsColumns";
import { copyEmailToClipboard } from "src/helpers/pocs";
import CustomTooltip from "src/components/ui/CustomTooltip";
import infoIcon from "src/assets/images/info-icon-colored.svg"
import sendEmail from "src/assets/images/send-email.svg"
import sendEmailDisable from "src/assets/images/send-email_disab;ed.svg"
import adminShadowUser from "src/assets/images/adminShadowUser.svg"
import adminShadowUserDisabled from "src/assets/images/adminShadowUser_disabled.svg"
import Ubuntu_logo from 'src/assets/images/ubuntu_logo.svg'
import Windows_logo from 'src/assets/images/windows_logo.svg'
import { getPocVoucherDataById } from "src/api/users";

export const PocVouchersColumns = (handleVoucherDetails, handleSendEmail, refreshData, isDisabled) => {
    return [
        {
            Header: " ",
            disableSortBy: true,
            accessor: (row) => {
                return (
                    <img className={`VM_status ${row.lab_assigned.name.toLowerCase().includes('window') ? '' : 'ubuntu_scale'}`}
                        title=""
                        src={row.lab_assigned.name.toLowerCase().includes('window') ? Windows_logo : Ubuntu_logo}
                        alt=""
                        height="17"
                        width="17"

                    />
                );
            },
            percentWidth: 2,
        },

        {
            Header: "Console VM Name",
            accessor: "name",
            disableSortBy: true,
            percentWidth: 20,
            Cell: ({ row }) => {
                let interval;
                const [pocStatus, setPocStatus] = useState({ status: row.original.status, vmState: row.original.lab_launch.deployment_state });
                const textObject = {
                    toBeCopied: row.original.voucher_url,
                    successMessage: 'Voucher URL copied to clipboard!',
                    errorMessage: 'Failed to copy Voucher URL:'
                }


                useEffect(() => {
                    if (row.original.poc_assigned.status != 'Active') return;
                    pollPocVoucherData();
                    return () => {
                        clearInterval(interval)
                    };

                }, [])

                const pollPocVoucherData = () => {
                    const getSateInterval = async () => {
                        if (row.original.lab_launch.deployment_state?.toLowerCase() == 'ready') return;
                        const data = await getPocVoucherDataById(row.original.id);
                        if ((row.original.status == 'un_claimed' && data.status == 'claimed') || (row.original.lab_launch.gid == null && data.lab_launch.gid)) {
                            refreshData();
                        }
                        setPocStatus((prevObj) => ({ ...prevObj, status: data.status, vmState: data.lab_launch.deployment_state }))
                        if (data.lab_launch.deployment_state?.toLowerCase() == 'ready') {
                            clearInterval(interval)
                        }
                    }
                    interval = setInterval(getSateInterval, 5000)
                }

                const getClassOnState = () => {
                    const CurrentState = pocStatus.vmState?.toLowerCase();
                    if (!CurrentState && pocStatus.status == 'un_claimed') {
                        return 'not_claimed'
                    }
                    else if (CurrentState == 'not ready' || (pocStatus.status == 'claimed' && !CurrentState)) {
                        return 'not_ready'
                    }
                    else if (CurrentState == 'partial deployment') {
                        return 'partial_deployment'
                    }
                    else if (CurrentState == 'not responding') {
                        return 'not_responding'
                    }
                    else if (CurrentState == 'ready') {
                        return 'ready'
                    }
                    return ''
                }
                return (
                    <div className="d-flex pos-rel">
                        <div title="" className="cursor-pointer" onClick={() => copyEmailToClipboard(textObject)}>
                            <a className="nav_link" href="#">
                                {row.original.name}
                            </a>
                        </div>
                        {(row.original.poc_assigned.status == 'Active' || row.original.poc_assigned.status == 'Deployment') && <span className={`vm_status ${getClassOnState()}`}></span>}
                    </div>
                );
            }
        },
        {
            Header: "Claimed By",
            accessor: "claimed_by.email",
            percentWidth: 20,
            disableSortBy: true,
            Cell: ({ value }) => {
                return value || "--";
            },
        },
        {
            Header: "Claimed Date",
            accessor: "claimed_on",
            percentWidth: 9,
            disableSortBy: true,
            Cell: ({ value }) => {
                return (value && formatDate(value)) || "--";
            },
        },
        {
            Header: "  ",
            disableSortBy: true,
            accessor: (row) => {
                return (
                    <div className="d-flex gap-1">
                        <div className={isDisabled ? '' : 'cursor-pointer'}>
                            <CustomTooltip text="Send Email" placement="top">
                                {!isDisabled ?
                                    <img
                                        title=""
                                        src={sendEmail}
                                        alt="send email"
                                        height="17"
                                        width="17"
                                        onClick={() => handleSendEmail(row.code)}
                                    />
                                    : <img
                                        title=""
                                        src={sendEmailDisable}
                                        alt=""
                                        height="17"
                                        width="17"

                                    />}
                            </CustomTooltip>
                        </div>
                        <div className="cursor-pointer">
                            <CustomTooltip text="More Details" placement="top">
                                <img

                                    src={infoIcon}
                                    alt="info"
                                    height="17"
                                    width="17"
                                    onClick={() => handleVoucherDetails(row.code)}
                                />
                            </CustomTooltip>
                        </div>
                        <div className={row.claimed_on && row.lab_launch.gid && (row.poc_assigned.status == 'Active') ? 'cursor-pointer' : 'cursor-none'}>
                            <CustomTooltip text="View Console" placement="top">

                                <a target="_blank" href={`${window.location.origin}/user/console?id=${row.lab_launch.gid}`} >     <img

                                    src={row.claimed_on && row.lab_launch.gid && (row.poc_assigned.status == 'Active') ? adminShadowUser : adminShadowUserDisabled}
                                    alt="view console"
                                    height="17"
                                    width="17" /></a>

                            </CustomTooltip>
                        </div>

                    </div>
                );
            },
            percentWidth: 9,
        },
    ]

};