import moment from "moment";

export const setLocalStorageItem = (key: string, value: string) =>
  window.localStorage.setItem(key, value);

export const getLocalStorageItem = (key: string) =>
  window.localStorage.getItem(key);

export const isUserAuthenticated = () => !!getLocalStorageItem("token");

export const checkDuplicates = (Yup) =>
  Yup.addMethod(
    Yup.string,
    "checkDuplicate",
    function (this, errorMsg, prop, list, isEditMode) {
      return this.test("checkDuplicateTest", errorMsg, function (value) {
        return isEditMode || !list.some((item) => item[prop] === value);
      });
    }
  );

export const invalidDomain = (Yup) =>
  Yup.addMethod(Yup.string, "invalidDomain", function (this, errorMsg) {
    return this.test("invalidDomainTest", errorMsg, function (value) {
      return !!value && value.substring(value.lastIndexOf(".") + 1).length >= 2;
    });
  });

const getColumnValueRecursive = (obj, props, prop) => {
  prop = props.shift();
  if (typeof obj[prop] === "object" && obj[prop] !== null) {
    return getColumnValueRecursive(obj[prop], props, prop);
  } else {
    prop = obj[prop];
    return prop;
  }
};

export const sortColumn = (row1, row2, prop, desc?) => {
  const keys = prop.split(".");
  let valueColumn1;
  let valueColumn2;
  if (keys.length > 1) {
    valueColumn1 = getColumnValueRecursive(row1.values, [...keys], "");
    valueColumn2 = getColumnValueRecursive(row2.values, [...keys], "");
  } else {
    valueColumn1 = row1.values[prop];
    valueColumn2 = row2.values[prop];
  }

  if (
    valueColumn1 &&
    valueColumn2 &&
    valueColumn1 !== "-" &&
    valueColumn2 !== "-"
  ) {
    if (valueColumn1.toLowerCase() > valueColumn2.toLowerCase()) {
      return 1;
    } else if (valueColumn1.toLowerCase() < valueColumn2.toLowerCase()) {
      return -1;
    } else return 0;
  } else if (
    (!valueColumn1 || valueColumn1 === "-") &&
    valueColumn2 &&
    valueColumn2 !== "-"
  ) {
    return desc ? -1 : 1;
  } else if (
    (!valueColumn2 || valueColumn2 === "-") &&
    valueColumn1 &&
    valueColumn1 !== "-"
  ) {
    return desc ? 1 : -1;
  }
  return 0;
};

export const sortByDate = (row1, row2, desc?, prop = "lastLogin") => {
  const valueColumn1 = row1.values[prop];
  const valueColumn2 = row2.values[prop];
  if (
    valueColumn1 &&
    valueColumn2 &&
    valueColumn1 !== "-" &&
    valueColumn2 !== "-"
  ) {
    if (moment(row1.values[prop]).isAfter(row2.values[prop])) {
      return 1;
    }
    return -1;
  } else if (
    (!valueColumn1 || valueColumn1 === "-") &&
    valueColumn2 &&
    valueColumn2 !== "-"
  ) {
    return desc ? -1 : 1;
  } else if (
    valueColumn1 &&
    valueColumn1 !== "-" &&
    (!valueColumn2 || valueColumn2 === "-")
  ) {
    return desc ? 1 : -1;
  }
  return 0;
};

export const isEmpty = (obj) => {
  if (!obj) {
    return true;
  }
  return Object.entries(obj).length === 0 && obj.constructor === Object;
};

export const getFileName = (path) => path.split("\\").pop().split("/").pop();

export const formatError = (errorObj) => {
  let apiError = "";
  for (const [key, val] of Object.entries(errorObj)) {
    if (key !== "hasError") {
      apiError += `${val instanceof Array ? val.join(", ") : val}`;
    }
  }
  return apiError;
};

export const parseJwtToken = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const checkAdminUser = () => {
  const token = getLocalStorageItem("token");
  const parsedToken = parseJwtToken(token);
  return parsedToken.admin_access;
};

export const htmlToText = (htmlString) => {
  var span = document.createElement("span");
  span.innerHTML = htmlString.replace(/↵/g, "\n");
  return span.textContent || span.innerText;
};

export const getDate = (momentDate) =>
  momentDate
    ? `${momentDate.year()}-${momentDate.month() + 1}-${momentDate.date()}`
    : "";

export const getUTCDateTime = (date: Date | null, isStart = true) => {
  if (date) {
    const time = isStart
      ? { hour: 0, minute: 0, second: 0, millisecond: 0 }
      : {
        hour: 24,
        minute: 59,
        second: 59,
        millisecond: 999,
      };
    const utcDate = moment(date).set(time).utc();
    return `${utcDate.year()}-${utcDate.month() + 1
      }-${utcDate.date()} ${utcDate.hour()}:${utcDate.minute()}:${utcDate.second()}`;
  }

  return "";
};

export const isPromiseReturned = <T = any>(promise: Promise<T>) => {
  const pendingState = { status: "pending" };

  return Promise.race([promise, pendingState]).then(
    (value) =>
      value === pendingState ? value : { status: "fulfilled", value },
    (reason) => ({ status: "rejected", reason }),
  );
}