import React from "react";
import { mapTemplate } from "src/helpers/labs";
import { FormikProps } from "formik";
import { Form } from "react-bootstrap";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import CustomTooltip from "src/components/ui/CustomTooltip";
import refreshIcon from "src/assets/images/time.svg";

declare interface TemplateProps {
  formik: FormikProps<any>;
  setConsoleVms: any;
  skyTapLabTemplates: any;
  getTemplates: any;
  consoleVms: any;
  vcdLabTemplates: any;
  pocLabTemplates: any;
  VCDPolicies: any;
  labDetails: any;
  vraLabTemplates: any;
}

const Templates: React.FC<TemplateProps> = ({
  formik,
  getTemplates,
  skyTapLabTemplates,
  setConsoleVms,
  consoleVms,
  vcdLabTemplates,
  vraLabTemplates,
  pocLabTemplates,
  VCDPolicies,
  labDetails,
}) => {
  if (formik.values.labProviderType === "SKYTAP") {
    return (
      <div className="lab-container">
        <Form.Group className="lab-container-inner align-items-center">
          <Form.Label>
            Lab Template
            <RequiredFieldSymbol />
          </Form.Label>
          <div>
            <Form.Control
              as="select"
              className="form-control"
              defaultValue={formik.values.labTemplate}
              onChange={(event: any) => {
                const data = event.target.selectedOptions[0];
                const consoleVms = skyTapLabTemplates.templates?.find(
                  (template) => template.id === data.id
                )?.vms || [];
                setConsoleVms(mapTemplate(consoleVms));
                formik.setFieldValue("labConsoleAccessVM", "");
                formik.setFieldValue("labSwitchVMs", []);
                formik.setFieldTouched("labTemplate");
                formik.setFieldValue("labTemplate", data.id ? data.value : "");
              }}
            >
              {skyTapLabTemplates.templates &&
                skyTapLabTemplates.templates.length > 0 ? (
                [<option key="default_option">Select</option>].concat(
                  skyTapLabTemplates.templates?.map((template) => (
                    <option
                      selected={template.name === formik.values.labTemplate}
                      key={template.id}
                      value={template.name}
                      id={template.id}
                    >
                      {template.name}
                    </option>
                  ))
                )
              ) : (
                <option key="no_option">No Lab template available</option>
              )}
            </Form.Control>
            {formik.touched.labTemplate && formik.errors.labTemplate && (
              <InputErrorMessage
                className={"ml-1"}
                errorMessage={formik.errors.labTemplate || ""}
              />
            )}
          </div>
          <CustomTooltip text="Refresh Templates">
            <img
              height="15"
              width="15"
              src={refreshIcon}
              alt="refresh"
              className="ml-2 mb-2 cursor-pointer"
              onClick={() =>
                getTemplates(false, formik.values?.labProviderType)
              }
            />
          </CustomTooltip>
        </Form.Group>
        <Form.Group className="lab-container-inner align-items-center">
          <Form.Label className="">
            Console Access VM Name
            <RequiredFieldSymbol />
          </Form.Label>
          <div>
            <Form.Control
              as="select"
              disabled={!formik.values.labTemplate}
              className="form-control"
              defaultValue={formik.values.labConsoleAccessVM}
              onChange={(event: any) => {
                const data = event.target.selectedOptions[0];
                formik.setFieldTouched("labConsoleAccessVM");
                formik.setFieldValue(
                  "labConsoleAccessVM",
                  data.id ? data.value : ""
                );
                formik.setFieldValue("labSwitchVMs", []);
              }}
            >
              {consoleVms ? (
                [<option key="default_option">Select</option>].concat(
                  consoleVms.map((consoleVm) => (
                    <option
                      selected={
                        consoleVm.name === formik.values.labConsoleAccessVM
                      }
                      key={consoleVm.id}
                      value={consoleVm.name}
                      id={consoleVm.id}
                    >
                      {consoleVm.name}
                    </option>
                  ))
                )
              ) : (
                <option key="no_option">
                  No Lab Console Access VM available
                </option>
              )}
            </Form.Control>
            {formik.touched.labConsoleAccessVM &&
              formik.errors.labConsoleAccessVM && (
                <InputErrorMessage
                  className={"error-message ml-1"}
                  errorMessage={formik.errors.labConsoleAccessVM || ""}
                />
              )}
          </div>
        </Form.Group>
      </div>
    );
  } else if (formik.values.labProviderType === "VCD") {
    return (
      <div className="lab-container">
        <Form.Group className="lab-container-inner align-items-center">
          <Form.Label>
            vApp Template
            <RequiredFieldSymbol />
          </Form.Label>
          <div>
            <Form.Control
              as="select"
              defaultValue={formik.values.labTemplate}
              onChange={(event: any) => {
                const data = event.target.selectedOptions[0];
                const consoleVms = vcdLabTemplates.templates.find(
                  (template) => template.id === data.id
                )?.vms;
                setConsoleVms(mapTemplate(consoleVms));
                formik.setFieldValue("labSwitchVMs", []);
                formik.setFieldTouched("labTemplate");
                formik.setFieldValue("labConsoleAccessVM", "");
                formik.setFieldTouched("labTemplate");
                formik.setFieldValue("labTemplate", data.id ? data.value : "");
              }}
            >
              {vcdLabTemplates.templates ? (
                [<option key="default_option">Select</option>].concat(
                  vcdLabTemplates.templates?.map((template) => (
                    <option
                      selected={template.name === formik.values.labTemplate}
                      key={template.id}
                      value={template.name}
                      id={template.id}
                    >
                      {template.name}
                    </option>
                  ))
                )
              ) : (
                <option key="no_option">No Lab template available</option>
              )}
            </Form.Control>
            {formik.touched.labTemplate && formik.errors.labTemplate && (
              <InputErrorMessage
                className={"error-message ml-1"}
                errorMessage={formik.errors.labTemplate || ""}
              />
            )}
          </div>
          <CustomTooltip text="Refresh Templates">
            <img
              height="15"
              width="15"
              src={refreshIcon}
              alt="refresh"
              className="ml-2 cursor-pointer"
              onClick={() =>
                getTemplates(false, formik.values?.labProviderType)
              }
            />
          </CustomTooltip>
        </Form.Group>
        <Form.Group className="lab-container-inner align-items-center">
          <Form.Label className="">
            Console Access VM Name
            <RequiredFieldSymbol />
          </Form.Label>
          <div>
            <Form.Control
              as="select"
              disabled={!formik.values.labTemplate}
              className="form-control"
              defaultValue={formik.values.labConsoleAccessVM}
              onChange={(event: any) => {
                const data = event.target.selectedOptions[0];
                formik.setFieldTouched("labConsoleAccessVM");
                formik.setFieldValue(
                  "labConsoleAccessVM",
                  data.id ? data.value : ""
                );
              }}
            >
              {consoleVms ? (
                [<option key="default_option">Select</option>].concat(
                  consoleVms.map((consoleVm) => (
                    <option
                      selected={
                        consoleVm.name === formik.values.labConsoleAccessVM
                      }
                      key={consoleVm.id}
                      value={consoleVm.name}
                      id={consoleVm.id}
                    >
                      {consoleVm.name}
                    </option>
                  ))
                )
              ) : (
                <option key="no_option">
                  No Lab Console Access VM available
                </option>
              )}
            </Form.Control>
            {formik.touched.labConsoleAccessVM &&
              formik.errors.labConsoleAccessVM && (
                <InputErrorMessage
                  className={"error-message ml-1"}
                  errorMessage={formik.errors.labConsoleAccessVM || ""}
                />
              )}
          </div>
        </Form.Group>
        <Form.Group className="lab-container-inner mt-3">
          <Form.Label>
            vCD Storage Policy
            <RequiredFieldSymbol />
          </Form.Label>
          <div>
            <Form.Control
              as="select"
              className="form-control"
              defaultValue={labDetails.vdcName}
              onChange={(event: any) => {
                const data = event.target.selectedOptions[0];
                formik.setFieldTouched("vdcName");
                formik.setFieldValue("vdcId", data.id ?? "");
                formik.setFieldValue("vdcName", data.id ? data.value : "");
              }}
            >
              <option>Select</option>
              {VCDPolicies.map((policy) => (
                <option
                  key={policy.id}
                  id={policy.id}
                  value={policy.name}
                  selected={formik.values.vdcId === policy.id}
                >
                  {policy.name}
                </option>
              ))}
            </Form.Control>
            {formik.touched.vdcName && formik.errors.vdcName && (
              <InputErrorMessage
                className={"error-message ml-1"}
                errorMessage={formik.errors.vdcName || ""}
              />
            )}
          </div>
        </Form.Group>
      </div>
    );
  }
  else if (formik.values.labProviderType === "HOSTEDPOC1") {
    return (
      <div className="lab-container">
        <Form.Group className="lab-container-inner align-items-center">
          <Form.Label>
            Lab Template
            <RequiredFieldSymbol />
          </Form.Label>
          <div>
            <Form.Control
              as="select"
              className="form-control"
              defaultValue={formik.values.labTemplate}
              onChange={(event: any) => {

                const data = event.target.selectedOptions[0];
                const consoleVms = pocLabTemplates.templates?.find(
                  (template) => template.id === data.id
                ) || [];
                setConsoleVms([consoleVms]);
                formik.setFieldValue("labConsoleAccessVM", data.value);
                formik.setFieldValue("labSwitchVMs", []);
                formik.setFieldTouched("labTemplate");
                formik.setFieldValue("labTemplate", data.id ? data.value : "");
              }}
            >
              {pocLabTemplates.templates &&
                pocLabTemplates.templates.length > 0 ? (
                [<option key="default_option">Select</option>].concat(
                  pocLabTemplates.templates?.map((template) => (
                    <option
                      selected={template.name === formik.values.labTemplate}
                      key={template.id}
                      value={template.name}
                      id={template.id}
                    >
                      {template.name}
                    </option>
                  ))
                )
              ) : (
                <option key="no_option">No Lab template available</option>
              )}
            </Form.Control>
            {formik.touched.labTemplate && formik.errors.labTemplate && (
              <InputErrorMessage
                className={"ml-1"}
                errorMessage={formik.errors.labTemplate || ""}
              />
            )}
          </div>
          <CustomTooltip text="Refresh Templates">
            <img
              height="15"
              width="15"
              src={refreshIcon}
              alt="refresh"
              className="ml-2 mb-2 cursor-pointer"
              onClick={() =>
                getTemplates(false, formik.values?.labProviderType)
              }
            />
          </CustomTooltip>
        </Form.Group>
        <Form.Group className="lab-container-inner align-items-center">
          <Form.Label className="">
            Console Access VM Name
            <RequiredFieldSymbol />
          </Form.Label>
          <div>
            <Form.Control
              as="select"
              disabled={true}
              className="form-control"
              defaultValue={formik.values.labConsoleAccessVM}
              onChange={(event: any) => {
                const data = event.target.selectedOptions[0];
                formik.setFieldTouched("labConsoleAccessVM");
                formik.setFieldValue(
                  "labConsoleAccessVM",
                  data.value
                );
                formik.setFieldValue("labSwitchVMs", []);
              }}
            >
              {consoleVms ? (
                [<option key="default_option">Select</option>].concat(
                  consoleVms.map((consoleVm) => (
                    <option
                      selected={
                        consoleVm.name === formik.values.labConsoleAccessVM
                      }
                      // key={consoleVms.id}
                      value={consoleVm.name}
                      id={consoleVm.id}
                    >
                      {consoleVm.name}
                    </option>
                  ))
                )
              ) : (
                <option key="no_option">
                  No Lab Console Access VM available
                </option>
              )}
            </Form.Control>
            {formik.touched.labConsoleAccessVM &&
              formik.errors.labConsoleAccessVM && (
                <InputErrorMessage
                  className={"error-message ml-1"}
                  errorMessage={formik.errors.labConsoleAccessVM || ""}
                />
              )}
          </div>
        </Form.Group>
      </div>
    );
  }

  const template = vraLabTemplates?.templates?.find(
    (template) => template.name === formik.values.labTemplate
  );
  const vraProjects = template?.projects || [];
  const vraVersions = template?.versions || [];
  const consoleAccessVMs = template?.vms || []

  return (
    <>
      <div className="lab-container">
        <Form.Group className="lab-container-inner align-items-center">
          <Form.Label>
            Lab Template
            <RequiredFieldSymbol />
          </Form.Label>
          <div>
            <Form.Control
              as="select"
              className="form-control"
              defaultValue={formik.values.labTemplate}
              onChange={(event: any) => {
                const data = event.target.selectedOptions[0];
                // const consoleVms = vraLabTemplates.templates?.find(
                //   (template) => template.id === data.id
                // ).vms;
                // setConsoleVms(mapTemplate(consoleVms));
                formik.setFieldValue("labConsoleAccessVM", "");
                formik.setFieldTouched("labTemplate");
                formik.setFieldValue("labTemplate", data.id ? data.value : "");
              }}
            >
              {vraLabTemplates.templates &&
                vraLabTemplates.templates.length > 0 ? (
                [<option key="default_option">Select</option>].concat(
                  vraLabTemplates.templates?.map((template) => (
                    <option
                      selected={template.name === formik.values.labTemplate}
                      key={template.id}
                      value={template.name}
                      id={template.id}
                    >
                      {template.name}
                    </option>
                  ))
                )
              ) : (
                <option key="no_option">No Lab template available</option>
              )}
            </Form.Control>
            {formik.touched.labTemplate && formik.errors.labTemplate && (
              <InputErrorMessage
                className={"error-message ml-1"}
                errorMessage={formik.errors.labTemplate || ""}
              />
            )}
          </div>
          <CustomTooltip text="Refresh Templates">
            <img
              height="15"
              width="15"
              src={refreshIcon}
              alt="refresh"
              className="ml-2 cursor-pointer"
              onClick={() =>
                getTemplates(false, formik.values?.labProviderType)
              }
            />
          </CustomTooltip>
        </Form.Group>
        <Form.Group className="lab-container-inner align-items-center">
          <Form.Label>
            Project
            <RequiredFieldSymbol />
          </Form.Label>
          <div>
            <Form.Control
              as="select"
              className="form-control"
              disabled={!formik.values.labTemplate}
              defaultValue={formik.values.vraProjectId}
              onChange={(event: any) => {
                const data = event.target.selectedOptions[0];
                formik.setFieldTouched("vraProjectId");
                formik.setFieldValue("vraProjectId", data.id ?? "");
              }}
            >
              {vraProjects ? (
                [<option key="default_option">Select</option>].concat(
                  vraProjects?.map((project) => (
                    <option
                      selected={project.id === formik.values.vraProjectId}
                      key={project.id}
                      value={project.id}
                      id={project.id}
                    >
                      {project.name}
                    </option>
                  ))
                )
              ) : (
                <option key="no_option">No project available</option>
              )}
            </Form.Control>
            {formik.touched.vraProjectId && formik.errors.vraProjectId && (
              <InputErrorMessage
                className={"error-message ml-1"}
                errorMessage={formik.errors.vraProjectId || ""}
              />
            )}
          </div>
        </Form.Group>
      </div>
      <div className="clearfix" />
      <div className="lab-container">
        <Form.Group className="lab-container-inner align-items-center">
          <Form.Label>
            Version
            <RequiredFieldSymbol />
          </Form.Label>
          <div>
            <Form.Control
              as="select"
              className="form-control"
              disabled={!formik.values.labTemplate}
              defaultValue={formik.values.vraVersionId}
              onChange={(event: any) => {
                const data = event.target.selectedOptions[0];
                formik.setFieldTouched("vraVersionId");
                formik.setFieldValue("vraVersionId", data.id ? data.value : "");
              }}
            >
              {vraVersions ? (
                [<option key="default_option">Select</option>].concat(
                  vraVersions?.map((version) => (
                    <option
                      selected={version.id === formik.values.vraVersionId}
                      key={version.id}
                      value={version.id}
                      id={version.id}
                    >
                      {version.id}
                    </option>
                  ))
                )
              ) : (
                <option key="no_option">No version available</option>
              )}
            </Form.Control>
            {formik.touched.vraVersionId && formik.errors.vraVersionId && (
              <InputErrorMessage
                className={"error-message ml-1"}
                errorMessage={formik.errors.vraVersionId || ""}
              />
            )}
          </div>
        </Form.Group>
        <Form.Group className="lab-container-inner align-items-center">
          <Form.Label className="">
            Console Access VM Name
            <RequiredFieldSymbol />
          </Form.Label>
          <div>
            <Form.Control
              as="select"
              id="vra-console-access-vm"
              className="form-control"
              disabled={!formik.values.labTemplate}
              defaultValue={formik.values.labConsoleAccessVM}
              onChange={(event: any) => {
                const data = event.target.selectedOptions[0];
                formik.setFieldTouched("labConsoleAccessVM");
                formik.setFieldValue("labConsoleAccessVM", data?.value ?? "");
              }}
            >
              {consoleAccessVMs.length > 0 ? (
                [<option key="default_option">Select</option>].concat(
                  consoleAccessVMs.map(vm => (
                    <option
                      selected={vm === formik.values.labConsoleAccessVM}
                      key={vm}
                      value={vm}
                      id={vm}
                    >
                      {vm}
                    </option>
                  ))
                )
              ) : (
                <option key="no_option">No Console Access VM available</option>
              )}
            </Form.Control>
            {formik.touched.labConsoleAccessVM &&
              formik.errors.labConsoleAccessVM && (
                <InputErrorMessage
                  className={"error-message ml-1"}
                  errorMessage={formik.errors.labConsoleAccessVM || ""}
                />
              )}
          </div>
        </Form.Group>
      </div>
    </>
  );
};

export default Templates;
