import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import './EventsList.scss'
import AddOrEditEvents from "src/components/AddOrEditEvents/AddOrEditEvents";
import SearchBox from "src/components/shared/SearchBox";
import { ReactComponent as AddIcon } from "src/assets/images/add.svg";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "src/state/events/actions";
import { RootState } from "src/state/rootReducer";
import { userTypesMap } from "src/constants/users";
import { usersAction } from "src/state/users/actions";
import { useLocation } from "react-router-dom";
import { getEventColumns } from "./EventListColumns";
import Table from "src/components/shared/Table";
import { deleteEventById, Events, Timezone } from "src/api/events";
import WarningModal from "src/components/shared/WarningModal";
import moment from 'moment-timezone';

const EventsList: React.FC = (props) => {

    const dispatch = useDispatch();
    const location = useLocation()
    const isEventManager = location.pathname.includes('user'); //to check if user is in Event Manager Flow or Global Admin Flow
    const [showAddorEditEventModal, setShowAddorEditEventModal] = useState(false);
    const [searchBy, setSearchBy] = useState(""); // Search query.
    const [filteredEvents, setFilteredEvents] = useState<Events[]>([]);
    const [isFiltering, setIsFiltering] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedEventData, setSelectedEventData] = useState<Events | null>();
    const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
    const [timezones, setTimezones] = useState<Timezone[]>();


    const eventData: Events[] = useSelector((state: RootState) => state.events.list);
    const corporateUserList: Array<any> = useSelector(
        (state: RootState) => (state.users.list).filter((user) => user.type == userTypesMap.corporate));



    useEffect(() => {
        dispatch(getEvents()); // Fetch events when the component mounts.
        getTimezone()
        if (corporateUserList.length === 0) {
            dispatch(usersAction());
        }
    }, []);



    const getTimezone = () => {
        // Define a list of popular timezones
        const popularTimezones = [
            'UTC',
            'America/New_York',
            'Europe/London',
            'Asia/Kolkata',
            'America/Los_Angeles',
            'Australia/Sydney',
            'Asia/Tokyo',
            'Europe/Paris',
            'America/Chicago',
            'Asia/Dubai',
        ];

        const allTimezones = moment.tz.names();

        // First, get the popular timezones and then the rest
        const popularTimezonesList = allTimezones.filter((timezone) => popularTimezones.includes(timezone));
        const remainingTimezonesList = allTimezones.filter((timezone) => !popularTimezones.includes(timezone));

        // Combine the popular timezones with the remaining timezones
        const orderedTimezones = [...popularTimezonesList, ...remainingTimezonesList];

        // Format the timezones
        const formattedTimezones = orderedTimezones.map((timezone) => {
            const offset = moment.tz(timezone).format('Z'); // Get GMT offset like +05:30
            const abbreviation = moment.tz(timezone).zoneName(); // Get the timezone abbreviation (e.g., IST, PST)
            const [county, city] = timezone.split('/'); // Split the timezone into county and city

            // Handle case for timezones with no city, like UTC, and properly format them
            const formattedCity = city ? city.replace('_', ' ') : county; // Replace underscore with space if present
            return { originalTimeZone: timezone, abbreviation: abbreviation, formatedLabel: `${formattedCity}, ${county} (GMT ${offset}, ${abbreviation})` }
        });
        setTimezones(formattedTimezones)
    }

    const createEvent = () => {
        setIsEditMode(false)
        setShowAddorEditEventModal(true)
    }

    const filterEventList = (searchQuery) => {
        let filteredEvents = eventData?.filter((event) => {
            if (!searchQuery) return true; // If no search query, return all events.
            const name = event.name && event.name.toLowerCase();
            const code = event.code && event.code.toLowerCase();
            const query = searchQuery && searchQuery.toLowerCase();
            return (code && code.includes(query)) || (name && name.includes(query)); // Match name or tags with the search query.
        });
        setIsFiltering(true); // Set filtering state to true before filtering starts
        setFilteredEvents(filteredEvents); // Update the filtered pocs list.
        setIsFiltering(false); // Set filtering state to false after updating

    };

    // Update filtered pocs whenever the pocs list changes.
    useEffect(() => {
        filterEventList(searchBy); // Filter based on the current search query.
    }, [eventData, searchBy]);

    // Handler to update the search query when the user types in the search box.
    const handleSearchByChange = (updatedSearchQuery) => {
        setSearchBy(updatedSearchQuery); // Update the search query state.
        filterEventList(updatedSearchQuery); // Filter the pocs list based on the new query.
    };


    function setSelected(id) {
        let selectedEvent = filteredEvents.find((item) => item.id == id)
        setSelectedEventData(selectedEvent)
    }
    const editEvent = (data) => {
        setIsEditMode(true);
        setSelected(data)
        setShowAddorEditEventModal(true)
    }

    const closeDeleteGroupModal = () => {
        setSelectedEventData(null)
        setShowDeleteWarningModal(false);
    };

    const deleteEventHandler = (id) => {
        setSelected(id)
        setShowDeleteWarningModal(true)
    }

    const onDeleteEvent = async () => {
        const response = await deleteEventById(selectedEventData?.id);
        if (response) {
            dispatch(getEvents());
            closeDeleteGroupModal();
        }
    }



    const eventColums = getEventColumns(timezones, editEvent, deleteEventHandler);



    return <>
        <div className={`pocs-list-section d-flex flex-column flex-grow-1 ${isEventManager ? 'event_manager' : ''}`}>
            {showDeleteWarningModal && (
                <WarningModal
                    title="Warning"
                    onCancelAction={closeDeleteGroupModal}
                    onConfirmAction={onDeleteEvent}
                >
                    <div className="ml-2">
                        <div>Are you sure you want to delete</div>
                        <div>
                            <b>{selectedEventData?.name}</b> ?
                        </div>
                    </div>
                </WarningModal>
            )}

            {showAddorEditEventModal && (
                <AddOrEditEvents
                    isEditMode={isEditMode}
                    selectedEvent={selectedEventData}
                    timezones={timezones}
                    setShowAddorEditEventModal={setShowAddorEditEventModal}
                />
            )}
            <div className="d-flex align-items-center justify-content-between mb-2">
                <div className={`pocs-list-section-header`}>
                    <span>
                        <b>Events</b>
                    </span>
                </div>
                <div className="d-flex align-self-center">
                    <div className="pocs-list-section-search d-flex align-items-center">
                        <SearchBox
                            updateSearchQuery={handleSearchByChange}
                            placeholder="Search by name or code"
                        />
                    </div>
                    {filteredEvents?.length ? (
                        <Button
                            type="submit"
                            size="sm"
                            className="ml-2"
                            variant="primary"
                            onClick={createEvent}
                        >
                            <div className="d-flex align-items-center pl-2 pr-2">
                                <AddIcon height="10" width="10" />
                                <span className="ml-2"> Create Event </span>
                            </div>
                        </Button>
                    ) : null}
                </div>
            </div>
            {/* Conditional rendering based on filtering status */}
            {isFiltering ? (
                <div>Loading...</div> // Show loading indicator while filtering
            ) : filteredEvents?.length ? (

                <Table searchBy={searchBy} columns={eventColums} data={filteredEvents} />
            ) : !!searchBy ? (
                <NoSearchResultFound
                    message="Your search was not successful!"
                    detail="Try some relevant name or code"
                    errImgSrc="no-users.svg"
                />
            ) : (
                (
                    <>
                        <NoSearchResultFound
                            message="No Events Created!"
                            detail="Click below to create a new Event"
                            errImgSrc="no-lab.svg"
                        />
                        <Button
                            type="submit"
                            size="sm"
                            className="ml-2 no-result-add-btn"
                            variant="primary"
                            onClick={createEvent}
                        >
                            <div className="d-flex align-items-center pl-2 pr-2">
                                <AddIcon height="10" width="10" />
                                <span className="ml-2"> Create Event </span>
                            </div>
                        </Button>
                    </>
                )
            )}
        </div>
    </>

}
export default EventsList;