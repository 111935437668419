import React, { useEffect, useRef, useState } from "react";
import PureModal from "../shared/PureModal";
import "./AddOrEditEvents.scss";
import { Form, Button } from "react-bootstrap";
import { Field, Formik } from "formik";
import RequiredFieldSymbol from "../ui/RequiredFieldSymbol";
import InputErrorMessage from "../shared/InputErrorMessage";
import DTPicker from "../shared/DateTimePicker";
import Select from "react-select";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { userTypesMap } from "src/constants/users";
import { convertDateFormat } from "src/helpers/pocs";
import { createEvents, Timezone, updateEvents } from "src/api/events";
import { getEvents } from "src/state/events/actions";
import moment from 'moment-timezone';


type AddOrEditPocEvents = {
    setShowAddorEditEventModal: (boolean) => void;
    isEditMode: boolean;
    timezones: Timezone[] | undefined;
    selectedEvent?: any
};

const newEvent = {
    name: '',
    description: '',
    code: '',
    participantCount: '',
    timezone: '',
    owner: '',
    start_date: '',
    start_time: '',
    end_date: '',
    end_time: '',


};
const AddOrEditEvents: React.FunctionComponent<AddOrEditPocEvents> = ({
    isEditMode,
    timezones,
    ...props
}) => {

    const dispatch = useDispatch();
    // const [modalLoaded, setModalLoaded] = useState(true);
    const initialValues = isEditMode ?
        {
            ...props.selectedEvent,
            start_time: convertToUTC(props.selectedEvent.start_date, props.selectedEvent.start_time, true),
            end_time: convertToUTC(props.selectedEvent.end_date, props.selectedEvent.end_time, true),
            timezone: { value: props.selectedEvent?.utc_date_time.actual_timezone },
            owner: props.selectedEvent.owner.id
        }
        : newEvent;
    const corporateUserList: Array<any> = useSelector(
        (state: RootState) => (state.users.list).filter((user) => user.type == userTypesMap.corporate));

    const validationSchema = Yup.object({
        name: Yup.string().required("Name name is required"),
        description: Yup.string().required("Description name is required"),
        code: Yup.string().required('Code is required'),
        participantCount: Yup.string().required('Total Participant is required'),
        timezone: Yup.string().required('Timezone is required'),
        owner: Yup.string().required("Owner is required"),
        start_date: Yup.string().required("Start Date is required"),
        start_time: Yup.string().required("Start Time is required"),
        end_date: Yup.string().required("End Date is required")
            .test('after-start', 'End date must be same or greater than start date', function (this, value) {
                const startDate = this.parent.start_date; // Get start_date from parent values
                return (startDate && (value && (new Date(value) >= new Date(startDate))));  // Ensure end date is after start date
            }),
        end_time: Yup.string().required("End Time is required")
            .test('after-end', 'End time must be after the start time', function (this, value) {
                if (!(this.parent.start_date && this.parent.start_time && this.parent.end_date && this.parent.end_time)) return;
                return ((+new Date(this.parent.end_time) - +new Date(this.parent.start_time)) >= 0);  // Ensure end date is after start date
            }),

    })



    function convertToUTC(date, time, toNormaltime = false) {
        let dateUTC = new Date(date);
        let timeUTC = time.split(':')
        dateUTC.setHours(timeUTC[0])
        dateUTC.setMinutes(timeUTC[1])
        return toNormaltime ? dateUTC : dateUTC.toISOString();
    }
    function convertDateTimeToUTC(date, time, timezone) {
        // Combine date and time
        const dateTime = `${date} ${time}`;

        // Convert to the timezone and then to UTC
        const utcTime = moment.tz(dateTime, "YYYY-MM-DD HH:mm", timezone).utc().format();

        return utcTime;
    }

    function getTime(time) {
        return `${String(time.getHours()).padStart(2, '0')}:${String(time.getMinutes()).padStart(2, '0')}`
    }
    const getEndminTime = (sDate, sTime, eDate) => {
        if (!(sDate && sTime && eDate) || sDate != eDate) return ''
        let minDate = new Date(sDate)
        minDate.setHours(sTime.getHours())
        minDate.setMinutes(sTime.getMinutes())
        return minDate;
    }

    const getPayload = (event) => {
        let payload = {
            name: event.name,
            description: event.description,
            start_date: convertDateFormat(event.start_date),
            start_time: getTime(event.start_time),
            end_date: convertDateFormat(event.end_date),
            end_time: getTime(event.end_time),
            code: event.code,
            owner: event.owner,
            utc_date_time: {
                start_datetime: convertDateTimeToUTC(convertDateFormat(event.start_date), getTime(event.start_time), event.timezone.value),
                end_datetime: convertDateTimeToUTC(convertDateFormat(event.end_date), getTime(event.end_time), event.timezone.value),
                actual_timezone: event.timezone.value,
            }
        }
        return payload;
    }




    const onSubmitHandle = async (eventData) => {
        const payload = getPayload(eventData)
        let response;
        if (isEditMode) {
            response = await updateEvents(payload, props.selectedEvent.id)
        }
        else {
            response = await createEvents(payload)
        }
        if (response) {
            props.setShowAddorEditEventModal(false);
            dispatch(getEvents());
        }

    }

    return <>

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitHandle}
            enableReinitialize
        >
            {(formik) => (
                <PureModal
                    title={isEditMode ? "Edit Event" : "Add Event"}
                    showModal={true}
                    size="lg"
                    closeModal={() => props.setShowAddorEditEventModal(false)}
                    primaryBtnLabel={isEditMode ? "Save" : "Add"}
                    primaryBtnAction={formik.handleSubmit}
                    secondaryBtnLabel={"Cancel"}
                    secondaryBtnAction={() => props.setShowAddorEditEventModal(false)}
                >

                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Form >
                            <div className="poc-container">
                                <div className="poc-form-wrapper">

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Name
                                            <RequiredFieldSymbol />
                                        </Form.Label>
                                        <div>
                                            <Form.Control
                                                type="text"
                                                id="name"
                                                autoComplete="Off"
                                                {...formik.getFieldProps("name")}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <InputErrorMessage
                                                    className={"error-message ml-1"}
                                                    errorMessage={formik.errors.name || ""}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Description
                                            <RequiredFieldSymbol />
                                        </Form.Label>
                                        <div>
                                            <Form.Control
                                                type="text"
                                                id="description"
                                                autoComplete="Off"
                                                {...formik.getFieldProps("description")}
                                            />
                                            {formik.touched.description && formik.errors.description && (
                                                <InputErrorMessage
                                                    className={"error-message ml-1"}
                                                    errorMessage={formik.errors.description || ""}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Code
                                            <RequiredFieldSymbol />
                                        </Form.Label>
                                        <div>
                                            <Form.Control
                                                type="text"
                                                id="code"
                                                autoComplete="Off"
                                                {...formik.getFieldProps("code")}
                                            />
                                            {formik.touched.code && formik.errors.code && (
                                                <InputErrorMessage
                                                    className={"error-message ml-1"}
                                                    errorMessage={formik.errors.code || ""}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>


                                    <Form.Group className="mb-4">
                                        <Form.Label >
                                            Owner
                                            <RequiredFieldSymbol />
                                        </Form.Label>
                                        <div>
                                            <Select
                                                // isDisabled={isEditMode}
                                                id='owner'
                                                className="select-nameEmail"
                                                size={5}
                                                placeholder="Search name or email"
                                                defaultValue={{
                                                    value: formik.values.owner,
                                                    label: `${isEditMode ? props.selectedEvent.owner.name + ',' + props.selectedEvent.owner.email : formik.values.owner} `,
                                                }}
                                                isClearable={true}
                                                onChange={(data) => {
                                                    formik.setFieldTouched("owner");
                                                    formik.setFieldValue(
                                                        "owner",
                                                        data && data.value ? data.value : ""
                                                    )
                                                }}
                                                options={corporateUserList.map((resource) => {
                                                    return {
                                                        value: resource.id,
                                                        label: `${resource.name}, ${resource.email}`

                                                    };
                                                })}
                                            />
                                            {formik.touched.owner && formik.errors.owner && (
                                                <InputErrorMessage
                                                    className={"error-message"}
                                                    errorMessage={formik.errors.owner || ""}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Total Participant
                                            <RequiredFieldSymbol />
                                        </Form.Label>
                                        <div>
                                            <Form.Control
                                                type="number"
                                                id="participantCount"
                                                autoComplete="Off"
                                                {...formik.getFieldProps("participantCount")}
                                            />
                                            {formik.touched.participantCount && formik.errors.participantCount && (
                                                <InputErrorMessage
                                                    className={"error-message ml-1"}
                                                    errorMessage={formik.errors.participantCount || ""}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label >
                                            Timezone
                                            <RequiredFieldSymbol />
                                        </Form.Label>
                                        <div>
                                            <Select
                                                // isDisabled={isEditMode}
                                                id='timezone'
                                                className="select-nameEmail"
                                                size={5}
                                                placeholder="Search Timezones"
                                                isClearable={true}
                                                defaultValue={{
                                                    value: formik.values.timezone,
                                                    label: `${isEditMode ? timezones?.find(x => x.originalTimeZone === formik.values.timezone.value)?.formatedLabel : ''} `,
                                                }}
                                                onChange={(data) => {
                                                    formik.setFieldTouched("timezone");
                                                    formik.setFieldValue(
                                                        "timezone",
                                                        data ? data : ''
                                                    )
                                                }}
                                                options={timezones?.map((zone) => {
                                                    return {
                                                        value: zone.originalTimeZone,
                                                        label: zone.formatedLabel

                                                    };
                                                })}
                                            />
                                            {formik.touched.timezone && formik.errors.timezone && (
                                                <InputErrorMessage
                                                    className={"error-message"}
                                                    errorMessage={formik.errors.timezone || ""}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>Start Date <RequiredFieldSymbol /></Form.Label>
                                        <div>
                                            <Field name="start_date">
                                                {({ field }) => (
                                                    <DTPicker
                                                        {...field}
                                                        selectedDate={formik.values.start_date ? new Date(formik.values.start_date) : null}
                                                        autoComplete="Off"
                                                        onChange={(date) => {

                                                            if (formik.values.start_time) {
                                                                let newDate = new Date(formik.values.start_time)
                                                                newDate.setMonth(date.getMonth())
                                                                newDate.setDate(date.getDate())
                                                                newDate.setFullYear(date.getFullYear())
                                                                formik.setValues({
                                                                    ...formik.values, start_time: newDate, start_date: date.toLocaleDateString('en-US', {
                                                                        year: 'numeric',
                                                                        month: '2-digit',
                                                                        day: '2-digit',
                                                                    })
                                                                })
                                                            }
                                                            else {
                                                                formik.setFieldValue("start_date", date.toLocaleDateString('en-US', {
                                                                    year: 'numeric',
                                                                    month: '2-digit',
                                                                    day: '2-digit',
                                                                }))
                                                            }
                                                            // formik.validateField('end_date')
                                                        }
                                                        }
                                                        showTimeSelect={false}
                                                        // timeFormat="HH:mm"
                                                        // timeIntervals={15}
                                                        dateFormat="mm/dd/yyyy"
                                                        className="date-picker-container form-control"
                                                        placeholderText="Enter Start Date"
                                                    />
                                                )}
                                            </Field>
                                            {formik.touched.start_date && formik.errors.start_date && (
                                                <InputErrorMessage
                                                    className={"error-message"}
                                                    errorMessage={formik.errors.start_date || ""}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>Start Time<RequiredFieldSymbol /></Form.Label>
                                        <div>
                                            <Field name="start_time">
                                                {({ field }) => (
                                                    <DTPicker
                                                        {...field}
                                                        selectedDate={formik.values.start_time}
                                                        autoComplete="Off"
                                                        onChange={(time) => {
                                                            if (formik.values.start_date) {
                                                                const sDate = formik.values.start_date.split('/')
                                                                time.setFullYear(sDate[2])
                                                                time.setMonth(sDate[0] - 1)
                                                                time.setDate(sDate[1])
                                                            }
                                                            formik.setFieldValue("start_time", time)
                                                        }
                                                        }
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="HH:mm"
                                                        className="date-picker-container form-control"
                                                        placeholderText="Enter Start Time"
                                                    />
                                                )}
                                            </Field>
                                            {formik.touched.start_time && formik.errors.start_time && (
                                                <InputErrorMessage
                                                    className={"error-message"}
                                                    errorMessage={formik.errors.start_time || ""}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>


                                    <Form.Group className="mb-4">
                                        <Form.Label>End Date <RequiredFieldSymbol /></Form.Label>
                                        <div>
                                            <Field name="end_date">
                                                {({ field }) => (
                                                    <DTPicker
                                                        {...field}
                                                        selectedDate={formik.values.end_date ? new Date(formik.values.end_date) : null}
                                                        autoComplete="Off"
                                                        onChange={(date) => {
                                                            formik.setFieldTouched("end_date");

                                                            if (formik.values.end_time) {
                                                                let newDate = new Date(formik.values.end_time)
                                                                newDate.setMonth(date.getMonth())
                                                                newDate.setDate(date.getDate())
                                                                newDate.setFullYear(date.getFullYear())
                                                                formik.setValues({
                                                                    ...formik.values, end_time: newDate, end_date: date.toLocaleDateString('en-US', {
                                                                        year: 'numeric',
                                                                        month: '2-digit',
                                                                        day: '2-digit',
                                                                    })
                                                                })
                                                            }
                                                            else {
                                                                formik.setFieldValue("end_date", date.toLocaleDateString('en-US', {
                                                                    year: 'numeric',
                                                                    month: '2-digit',
                                                                    day: '2-digit',
                                                                }))
                                                            }

                                                        }
                                                        }
                                                        showTimeSelect={false}
                                                        minDate={new Date(formik.values.start_date)}
                                                        dateFormat="mm/dd/yyyy"
                                                        className="date-picker-container form-control"
                                                        placeholderText="Enter End Date"
                                                    />
                                                )}
                                            </Field>
                                            {(formik.touched.end_date || formik.values.end_time) && formik.errors.end_date && (
                                                <InputErrorMessage
                                                    className={"error-message"}
                                                    errorMessage={formik.errors.end_date || ""}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>End Time <RequiredFieldSymbol /></Form.Label>
                                        <div>
                                            <Field name="end_time">
                                                {({ field }) => (
                                                    <DTPicker
                                                        {...field}
                                                        selectedDate={formik.values.end_time}
                                                        autoComplete="Off"
                                                        onChange={(time) => {
                                                            formik.setFieldTouched("end_time");
                                                            if (formik.values.end_date) {
                                                                const eDate = formik.values.end_date.split('/')
                                                                time.setFullYear(eDate[2])
                                                                time.setMonth(eDate[0] - 1)
                                                                time.setDate(eDate[1])
                                                            }
                                                            formik.setFieldValue("end_time", time)
                                                        }
                                                        }
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        minTime={getEndminTime(formik.values.start_date, formik.values.start_time, formik.values.end_date)}
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="HH:mm"
                                                        className="date-picker-container form-control"
                                                        placeholderText="Enter End Time"
                                                    />
                                                )}
                                            </Field>
                                            {(formik.touched.end_time || formik.values.end_time) && formik.errors.end_time && (
                                                <InputErrorMessage
                                                    className={"error-message"}
                                                    errorMessage={formik.errors.end_time || ""}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </Form>


                    </Form>

                </PureModal>
            )}
        </Formik>

    </>




}

export default AddOrEditEvents;