
import { fetchEvents } from "src/api/events";
import { GET_EVENTS_SUCCESS } from "./reducer";


export const getEvents = (metaConfig = { showLoader: true }) => async (
    dispatch
) => {
    let data = await fetchEvents(metaConfig);
    if (data) {
        //let pocs = mapAdminPocs(data);
        dispatch({ type: GET_EVENTS_SUCCESS, payload: data.results });
    }
};
