import React from "react";
import CustomTooltip from "src/components/ui/CustomTooltip";
import editIcon from "src/assets/images/editIcon.svg";
import deleteIcon from "src/assets/images/delete.svg";

export const getEventColumns = (timezones, editEvent, deleteEventHandler) => {
    return [

        {
            Header: "Event Name",
            accessor: "name",
            percentWidth: 6,

        },
        {
            Header: "Code",
            accessor: "code",
            percentWidth: 6,

        },

        {
            Header: "Owner",
            accessor: "owner.name",
            percentWidth: 10,
            disableSortBy: true,
        },

        {
            Header: "Start Date & Time",
            accessor: "start_date",
            percentWidth: 10,
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    `${row.original.start_date} ${row.original.start_time} (${timezones.find(x => x.originalTimeZone === row.original.utc_date_time.actual_timezone)?.abbreviation})`
                );
            },
        },

        {
            Header: "End Date & Time",
            accessor: "end_date",
            percentWidth: 10,
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    `${row.original.end_date} ${row.original.end_time} (${timezones.find(x => x.originalTimeZone === row.original.utc_date_time.actual_timezone)?.abbreviation})`
                );
            },
        },
        {
            Header: "Actions",
            disableSortBy: true,
            accessor: (row) => {
                return (
                    <div className="d-flex gap-1">
                        <div title="" className="cursor-pointer">
                            <CustomTooltip text="Edit Event" placement="top">
                                <img
                                    title=""
                                    src={editIcon}
                                    alt="editIcon"
                                    height="30"
                                    width="30"
                                    onClick={() => editEvent(row.id)}
                                />
                            </CustomTooltip>
                        </div>
                        <div
                            title=""
                            className="cursor-pointer action-icon action-icon-lab-list ml-2"
                        >
                            <CustomTooltip text="Delete Event" placement="top">
                                <img
                                    title=""
                                    src={deleteIcon}
                                    alt="deleteIcon"
                                    height="16"
                                    width="16"
                                    onClick={() => deleteEventHandler(row.id)}
                                />
                            </CustomTooltip>
                        </div>
                    </div>
                );
            },
            percentWidth: 9,
        },
    ]

};