import React, { useState, useEffect } from "react";
import "./PocDetails.scss";
import { Link, useParams } from "react-router-dom";
import rightArrow from "src/assets/images/right-arrow.svg";
import editPocIcon from "src/assets/images/poc-edit.svg"
import addVmIcon from "src/assets/images/add-consoleVM.svg"
import addVmIconDisable from "src/assets/images/add-consoleVM _disable.svg"
import { IPoc } from "src/state/pocs/reducer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { getPocs } from "src/state/pocs/actions";
import { getPocDetails } from "src/api/poc";
import { getPocVoucherData, getUsersbyId } from "src/api/users";
import { mapAdminPocs, mapPocDetails, mapPOCDetailsbyId, daysBetween } from "src/helpers/pocs";
import { formatDate } from "../PocsColumns";
import AddOrEditPoc from "src/components/AddOrEditPoc/AddOrEditPoc";
import UserVoucherModal from "src/components/user/UserVoucherModal";
import { pushNotification } from "src/components/ui/Notification";

import { PocVouchersColumns } from "./PocVoucherColumns";
import Table from "src/components/shared/Table";
import * as Yup from "yup";
import { Button, Form } from "react-bootstrap";
import { ErrorMessage, Field, Formik } from "formik";
import PureModal from "src/components/shared/PureModal";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import { updatePocVoucher } from "src/api/vouchers";


const poc = {
    pocId: -1,
    createdOn: '',
    updatedOn: "",
    labProviderType: "",
    status: "",
    company: "",
    accountAe: "",
    oppId: "",
    requestedStart: null,
    actualStart: null,
    endDate: null,
    podNumber: -1,
    testPlan: null,
    theatre: "",
    stage: "",
    deployedBy: "",
    accountSe: "",
    activeDate: '',
    assignedUsers: [],
    assignedGroups: [],
    assignedCompanies: [],
    vcForUsers: [],
    vcForGroups: [],
    vcForCompanies: [],
    se_user: {
        id: "",
        email: "",
        name: "",
        company: null,
        type: "",
        userType: -1,
        role: "",
        userRole: -1,
        isActive: null,
        isInvited: null,
        labsLaunched: -1,
        lastLogin: "",
        status: ""
    }
}

// Define the interface for each nested object
interface InvitedBy {
    id: string;
    email: string;
    name: string;
}

interface PocAssigned {
    poc_id: number;
    status: string;
}

interface Voucher {
    name: string;
    invited_by: InvitedBy;
    claimed_by: string | null;
    claimed_on: string | null;
    voucher_url: string;
    email: string;
    code: string;
    poc_assigned: PocAssigned;
    status: string;
    created_on: string;
}

const PocDetails: React.FC<any> = () => {

    const [pocData, setPocData] = useState(poc);
    const [showAddorEditPocModal, setShowAddorEditPocModal] = useState(false)
    const [editType, setEditType] = useState(0)
    const pocs: any = useSelector((state: RootState) => state.pocs.list);
    const userList: Array<any> = useSelector((state: RootState) => (state.users.list));
    const [pocVoucherModal, setPocVoucherModal] = useState(false)
    const [pocVoucherDetailsModal, setPocVoucherDetailsModal] = useState(false)
    const [selectedVoucher, setSelectedVoucher] = useState<Voucher>();
    const [pocVoucherData, setPocVoucherData] = useState<Voucher[]>([]);
    const [showEmailModal, setShowEmailModal] = useState(false)
    const { pocId }: { pocId } = useParams();

    const emailValidationSchema = Yup.object({
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
    });

    useEffect(() => {
        getPOCVoucherData();
        //on refersh Page
        if (pocs.length === 0) {
            const fetchPocbyId = async () => {
                let pocData = await getPocDetails(pocId);
                pocData = mapPOCDetailsbyId(pocData);
                pocData.se_user = await getUsersbyId(pocData.accountSe)
                pocData.activeDate = pocData.status_history.find(x => x.status == 'Active')?.effective_date
                setPocData(pocData);

            }
            fetchPocbyId()
        }
        //from poc listing page
        else {
            const fetchPocbyId = async () => {
                let pocData = pocs.find((item) => item.pocId == pocId);
                let userData = userList.find((item) => item.id == pocData?.accountSe) || await getUsersbyId(pocData.accountSe);
                pocData = { ...pocData, se_user: userData }
                pocData.activeDate = pocData.statusHistory.find(x => x.status == 'Active')?.effectiveDate
                setPocData(pocData)

            }
            fetchPocbyId()
        }
    }, [pocs])


    //get POC voucher Data by Id
    const getPOCVoucherData = async (showLoader = true) => {
        const data = await getPocVoucherData(pocId, showLoader);
        setPocVoucherData(data.results.sort((a, b) => {
            if (a.status === "claimed" && b.status === "un_claimed") {
                return -1; // "claimed" comes first
            }
        }));
        if (selectedVoucher) {
            setSelectedVoucher(data.results.find(x => x.name == selectedVoucher.name))
        }
    }
    //on More Details Click
    const handleVoucherDetails = (code) => {
        const filteredVoucher = pocVoucherData.find(x => x.code == code);
        filteredVoucher && setSelectedVoucher(filteredVoucher)
        setPocVoucherDetailsModal(true);
    }
    //on Add Recipient Email Modal Submit
    const handleSendEmailClick = (code) => {
        const filteredVoucher = pocVoucherData.find(x => x.code == code);
        filteredVoucher && setSelectedVoucher(filteredVoucher)
        if (filteredVoucher?.email) {
            sendEmail(filteredVoucher?.email, filteredVoucher)
        }
        else {
            setShowEmailModal(true)
        }
    }

    const handleEmailSubmit = (data) => {
        sendEmail(data.email, selectedVoucher)
    }

    //api call to send Email
    const sendEmail = async (data, voucherData) => {
        const payload = { email: data, send_email: true };
        const response = await updatePocVoucher(voucherData.id, payload);
        if (response) {
            getPOCVoucherData();
            setShowEmailModal(false)
        }
    }
    const isDisabled = !(pocData.status == 'Active' || pocData.status == 'Deployment');
    const refreshData = () => {
        getPOCVoucherData(false)
    }

    //on Edit Icon Click
    const pocColumn = PocVouchersColumns(handleVoucherDetails, handleSendEmailClick, refreshData, isDisabled)
    const handleEditPoc = (type) => {
        setEditType(type)
        setShowAddorEditPocModal(true)
    }


    //on hosted poc console
    const handlePocVoucher = () => {
        if (!(pocData.status == 'Deployment' || pocData.status === 'Active')) {
            pushNotification({ type: "info", message: `Voucher cannot be generated as the POC status is ${pocData.status}` });
            return;
        }
        setPocVoucherModal(!pocVoucherModal)
    }

    //To generate Voucher Name
    const generateVoucherName = () => {
        const largestNumber = pocVoucherData && Math.max(
            ...pocVoucherData
                .map(item => {

                    const match = item.name.match(/VM-(\d+)/);
                    return match ? parseInt(match[1], 10) : -Infinity;
                })
        );
        let consoleId = largestNumber === -Infinity || !largestNumber ? 1 : largestNumber + 1;
        return `POC${pocId}-POD-${pocData.podNumber}-VM-${String(consoleId).padStart(2, '0')}`
    }

    return <>
        {showAddorEditPocModal && (
            <AddOrEditPoc
                isEditMode={true}
                type={editType}
                selectedPoc={pocData}
                setShowAddorEditPocModal={setShowAddorEditPocModal}
            />
        )}

        <div className="tabs-layout">
            <div className={`pocs-list-section-header mb-3`}>
                <b>
                    <Link className="group-user-header" to="/admin/pocs">
                        POCs
                    </Link>
                </b>
                <img
                    height="15"
                    width="15"
                    src={rightArrow}
                    alt="InfoIcon"
                    className="mr-1 ml-1"
                />
                <span className="h4">Hosted POC Details</span>
            </div>
            {/* <span className="mb-2 tabs-layout-header"><b> Hosted POC Details </b></span> */}
            <div className="details_container">
                <div className="card border-light px-4 py-3">
                    <div>
                        <InfoDetails lable="Opportunity Info" imageObj={{ img_url: editPocIcon, alt: 'Edit POC' }} handleClick={() => handleEditPoc(1)} >
                            <ul className="details_tab">
                                <InfoCapsules name="Company" value={pocData.company} />
                                <InfoCapsules name="Opportunity ID" value={pocData.oppId} />
                                <InfoCapsules name="Account SE" value={pocData.se_user.name} />
                                <InfoCapsules name="Account AE" value={pocData.accountAe} />
                                <InfoCapsules name="Theatre" value={pocData.theatre} />
                                <InfoCapsules name="Stage" value={pocData.stage} />
                            </ul>
                        </InfoDetails>
                    </div>
                    <div>
                        <InfoDetails lable="General Info" imageObj={{ img_url: editPocIcon, alt: 'Edit POC' }} handleClick={() => handleEditPoc(2)} >
                            <ul className="details_tab">
                                <InfoCapsules name="Created On" value={pocData.createdOn && formatDate(pocData.createdOn)} />
                                <InfoCapsules name="Status" value={pocData.status} />
                                <InfoCapsules name="Requested Start" value={pocData.requestedStart && formatDate(pocData.requestedStart)} />
                                <InfoCapsules name="Actual Start" value={pocData.activeDate && formatDate(pocData.activeDate)} />
                                <InfoCapsules name="Days Active" value={daysBetween(pocData.activeDate)} />
                                <InfoCapsules name="Date Closed" value="" />
                            </ul>
                        </InfoDetails>
                    </div>
                </div>
                <div className="card border-light px-4 py-3">
                    <div>
                        <InfoDetails lable="Hosted POC Console" imageObj={{ img_url: addVmIcon, alt: 'Add Console VM', isDisabled: pocVoucherData?.length >= 20 ? true : false, img_disable_url: addVmIconDisable }} handleClick={handlePocVoucher} ><div></div>
                            {pocVoucherModal && (
                                <UserVoucherModal setShowModal={setPocVoucherModal} voucherAdded={getPOCVoucherData} type='poc' voucherName={generateVoucherName} />
                            )}
                            {pocVoucherDetailsModal &&
                                <PureModal
                                    title='Voucher Details'
                                    showModal={true}
                                    closeModal={() => setPocVoucherDetailsModal(false)}
                                    // size='lg'
                                    // primaryBtnLabel="Generate"
                                    // primaryBtnAction={formik.handleSubmit}
                                    secondaryBtnLabel="Close"
                                    secondaryBtnAction={() => setPocVoucherDetailsModal(false)}>
                                    <VoucherDetails selectedVoucher={selectedVoucher} emailValidation={emailValidationSchema} getPOCVoucherData={getPOCVoucherData} isDisabled={isDisabled} />

                                </PureModal>
                            }
                            {showEmailModal &&

                                <Formik
                                    initialValues={{ email: '' }}
                                    validationSchema={emailValidationSchema}
                                    onSubmit={handleEmailSubmit}
                                >
                                    {(formik) => (
                                        <PureModal
                                            title='Add Recipient Email'
                                            showModal={true}
                                            closeModal={() => setShowEmailModal(false)}
                                            primaryBtnLabel="Send Email"
                                            primaryBtnAction={formik.handleSubmit}
                                            secondaryBtnLabel="Close"
                                            secondaryBtnAction={() => setShowEmailModal(false)}

                                        >
                                            <EmailTemplate isModal={true} formik={formik} isDisabled={false} />
                                        </PureModal>
                                    )}

                                </Formik>
                            }
                            <div className="poc_voucher_wrapper">
                                <Table columns={pocColumn} data={pocVoucherData} paginationOff={true} />
                            </div>
                        </InfoDetails>
                    </div>
                </div>
                <div className="card border-light px-4 py-3">
                    <ul className="details_tab">
                        <InfoDetails lable="Assigned Resources" isCapsule={true}> <InfoCapsules name="Assigned POD" value={pocData.podNumber} /></InfoDetails></ul></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Notes/Comments"><div></div></InfoDetails></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Attachments"><div></div></InfoDetails></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Activities"><div></div></InfoDetails></div>
            </div>
        </div>

    </>

}
export default PocDetails;


//---------------HELPER COMPONENTS-----------------------

const InfoDetails = ({ lable, children, ...props }) => {
    return <>
        <label className='card_header'>{lable} </label>
        {props.imageObj &&
            <img className={`float-right mt-1 ${props.imageObj?.isDisabled ? '' : 'cursor-pointer'}`} title="" src={props.imageObj.isDisabled ? props.imageObj.img_disable_url : props.imageObj.img_url} alt={props.imageObj.alt} height="18" width="18" onClick={props.imageObj.isDisabled ? '' : props.handleClick}></img>
        }
        {children}
    </>
}
const InfoCapsules = ({ name, value }) => {
    return <>
        <li className="d-flex">
            <div>{name}</div>
            <div>{value}</div>
        </li>
    </>
}

const VoucherDetails = ({ selectedVoucher, emailValidation, getPOCVoucherData, isDisabled }) => {
    return <>
        <section className='basic_info'>
            <div className='card_sm'><div>Voucher Name</div><div>{selectedVoucher.name}</div></div>
            <div className='card_sm'><div>Status</div><div>{selectedVoucher.status === 'claimed' ? 'Claimed' : 'Un-Claimed'}</div></div>
        </section>

        <section className="user_data card_sm">
            <div className="first_col">
                <div><span>Invited By :</span><div className="value"> {selectedVoucher.invited_by.email} </div></div>
                <div><span>Created On :</span><div className="value"> {formatDate(selectedVoucher.created_on)}</div></div>
            </div>
            <div className="second_col">
                <div><span>Claimed By :</span><div className="value">{selectedVoucher.claimed_by?.email ? selectedVoucher.claimed_by.email : '--'}</div> </div>
                <div><span>Claimed On :</span> <div className="value">{selectedVoucher.claimed_on ? formatDate(selectedVoucher.claimed_on) : '--'}</div></div>
            </div>
        </section>

        <section className='basic_info voucher'>
            <div className='card_sm'><div>Voucher Code</div><div>{selectedVoucher.code}</div></div>
            <div className='card_sm _email'><div className="mb-1">Recipient Email</div><div><RecipientEmail voucherData={selectedVoucher} emailValidationSchema={emailValidation} getPOCVoucherData={getPOCVoucherData} isDisabled={isDisabled} /></div></div>
            <div className='card_sm'><div>Voucher URL</div><div><a href={selectedVoucher.voucher_url} target="blank">{selectedVoucher.voucher_url}</a></div></div>
        </section>
    </>
}

const RecipientEmail = ({ voucherData, isModal = false, emailValidationSchema, getPOCVoucherData, isDisabled }) => {
    const email = voucherData.email || ''
    const handleSubmit = async (data) => {
        const payload = { email: data.email, send_email: true };
        const response = await updatePocVoucher(voucherData.id, payload);
        if (response) {
            voucherData.email = data.email;
            getPOCVoucherData();
        }
    }
    return <>
        <Formik
            initialValues={{ email: voucherData.email || '' }}
            validationSchema={emailValidationSchema}
            onSubmit={handleSubmit}
        >
            {(formik) => (
                <div className='wrapper_r_email'>
                    <EmailTemplate formik={formik} isModal={isModal} isDisabled={isDisabled} />
                    {!isModal &&
                        <Button
                            //@ts-ignore
                            onClick={formik.handleSubmit}
                            className="pure-modal-footer-btn ml-2"
                            size="sm"
                            variant="primary"
                            disabled={isDisabled}
                        >
                            Send Email
                        </Button>
                    }
                </div>
            )}


        </Formik>


    </>
}

const EmailTemplate = ({ formik, isModal, isDisabled }) => {
    return <>
        <Form.Group>
            {isModal && <Form.Label> Recipient Email</Form.Label>}
            <Form.Control
                type="text"
                id="email"
                placeholder="Enter Email"
                {...formik.getFieldProps("email")}
                disabled={isDisabled}
                isInvalid={!!(formik.touched.email && formik.errors.email)}
            />
            {formik.touched.email && formik.errors.email && (
                <InputErrorMessage
                    className={"error-message"}
                    errorMessage={formik.errors.email || ""}
                />
            )}
        </Form.Group>
    </>
}