import Api from "src/helpers/request";
import { toCamelCaseKeys } from "src/helpers/common";

export const getAdminLabsEndpoint = "/labs";
export const getUserLabsEndpoint = "/user/labs";
export const activeLabsEndpoint = "/user/labs/active";
export const getLabDetailsEndpoint = "/labs";
export const getLabStatusEndpoint = "/user/labs/active/{labId}/state";
export const getLabApprovalStatusEndpoint = "/user/labs/active/approval-status";
export const getVoucherLabsEndpoint = "/vouchers/labs";
export const customerInfoEndPoint = "/user/labs/customer-info";
export const labImageUploadEndPoint = "/image/upload";
export const labVMSEndpoint = "/user/labs/active/{gid}/vms";
export const switchVMSEndpoint =
  "/user/labs/active/{gid}/switch-vm?console_vm={vmName}";
// export const vcdLabEndPoint = "/temp-web-console-url?console_vm_id=7f456ab9-3ac3-4784-8682-b2852dca2720"
export const vcdLabEndPoint = "/temp-web-console-url";

export const fetchUserLabs = (metaConfig = { showLoader: true }) => {
  return Api.call(
    {
      url: `${getUserLabsEndpoint}?pagination=false`,
      method: "GET",
    },
    metaConfig
  );
};

/* API to fetch labs list */
export const fetchAdminLabs = (metaConfig = { showLoader: true }) => {
  return Api.call(
    {
      url: `${getAdminLabsEndpoint}?pagination=false`,
      method: "GET",
    },
    metaConfig
  );
};

export const fetchVoucherLabs = (type, metaConfig = { showLoader: true }) => {
  return Api.call(
    {
      url: `${getVoucherLabsEndpoint}?pagination=false&is_hostedpoc=${type == 'poc' ? true : false}`,
      method: "GET",
    },
    metaConfig
  );
};

/* API to enable/disable lab */
export const enableLab = (data, id) => {
  return Api.call(
    { url: `${getAdminLabsEndpoint}/${id}`, method: "PATCH", data },
    { showLoader: true }
  );
};

/* API to delete lab */
export const deleteLabById = (labId) =>
  Api.call(
    {
      url: `${getAdminLabsEndpoint}/${labId}`,
      method: "DELETE",
    },
    { showLoader: true }
  );

export const fetchLabTemplates = (provider: string, cache = true) => {
  return Api.call(
    { url: `/templates?cache=${cache}&type=${provider}`, method: "GET" },
    { showLoader: true }
  );
};

export const deleteUserLabById = (labId, showErrorNotification = true) =>
  Api.call(
    {
      url: `${activeLabsEndpoint}/${labId}`,
      method: "DELETE",
    },
    { showLoader: true, showErrorNotification }
  );

export const fetchLaunchParams = (data) => {
  return Api.call(
    { url: `${getUserLabsEndpoint}`, method: "POST", data },
    { showLoader: true, loadingMessage: "Please Wait..." }
  );
};

export const uploadLabImage = (data) =>
  Api.call(
    { url: labImageUploadEndPoint, method: "POST", data },
    { showLoader: true }
  );

export const createAdminLab = (data) => {
  return Api.call(
    { url: `${getAdminLabsEndpoint}`, method: "POST", data },
    { showLoader: true }
  );
};

export const saveAdminLab = (data, labId) => {
  return Api.call(
    { url: `${getAdminLabsEndpoint}/${labId}`, method: "PUT", data },
    { showLoader: true }
  );
};

export const getLabDetails = (labId) => {
  return Api.call(
    { url: `${getLabDetailsEndpoint}/${labId}`, method: "GET" },
    { showLoader: true }
  );
};

export const getVmStatus = (labId) => {
  return Api.call(
    { url: `${getLabStatusEndpoint.replace(`{labId}`, labId)}`, method: "GET" },
    { showLoader: false, showErrorNotification: false }
  );
};

export const getVMApprovalStatus = (labIds) => {
  const data = labIds.map((id) => ({ launched_lab_id: id }));

  return Api.call(
    {
      url: getLabApprovalStatusEndpoint,
      method: "POST",
      data,
    },
    { showLoader: false, showErrorNotification: false }
  );
};

export const extendLabTime: (
  labId: string | null,
  consoleUrl?: string,
  width?: number,
  height?: number,
  resize?: boolean,
  extend?: boolean
) => any = (
  labId,
  consoleUrl,
  width,
  height,
  resize = false,
  extend = true
) => {
    const data: any = { extend, width, height, resize };
    if (consoleUrl) {
      data.console_url = consoleUrl
    }
    return Api.call(
      { url: `${activeLabsEndpoint}/${labId}`, method: "PUT", data },
      { showLoader: false, showErrorNotification: false }
    );
  };

export const postCustomerInfo = (data) => {
  return Api.call(
    { url: `${customerInfoEndPoint}`, method: "POST", data },
    { showLoader: true }
  );
};

export const getActiveLabById = (labId) =>
  Api.call(
    { url: `${activeLabsEndpoint}/${labId}`, method: "GET" },
    { showLoader: true, showErrorNotification: false }
  );

export const getLabVmURL = async (gid: string, vmName: string) => {
  const data = await Api.call(
    {
      url: switchVMSEndpoint.replace("{gid}", gid).replace("{vmName}", vmName),
    },
    { showLoader: true, showErrorNotification: false }
  );
  return toCamelCaseKeys(data);
};

export const visitVraApprovedLab = (labId: string) => Api.call({ url: `${activeLabsEndpoint}/${labId}/_visited`, method: "PUT" })