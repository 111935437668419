import React, { useState } from "react";
import PureModal from "src/components/shared/PureModal";
import { useHistory, useLocation } from "react-router-dom";
import { Tabs, Tab, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { postCustomerInfo, visitVraApprovedLab } from "src/api/lab";
import { launchConsole } from "src/helpers/labs";
import { fetchLaunchParams } from "src/api/lab";
import { TextInput, RadioInput } from "src/components/ui/FormInputs";
import "./LaunchLab.scss";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { PRESENTATION_ROUTE, CONSOLE_ROUTE } from "src/constants/appRoutes";
import { toCamelCaseKeys } from "src/helpers/common";
import { useDispatch, useSelector } from "react-redux";
import { setSidebar } from "src/state/sidebar/actions";
import { RootState } from "src/state/rootReducer";
import { isEmpty } from "src/utils/common";
import { cx } from "@emotion/css";
import { userTypesMap } from "src/constants/users";
import oppIdValidationSchema, { extractOppIdFromUrl } from "src/helpers/validationsSchema/oppIdValidationSchema";

Yup.addMethod(
  Yup.string,
  "customRequired",
  function (this, errorMsg, predicate) {
    return this.test("customRequiredTest", errorMsg, function (value) {
      return (!!value && predicate) || !predicate;
    });
  }
);

type LaunchLabProps = {
  lab: {
    name: string;
    id: string;
    gid: string;
    customerInfoRequired: boolean;
    isActive?: boolean;
    lab?: {
      id: number;
      labProviderConfig: {
        isApprovalRequired: boolean;
        subdescription?: string;
      };
    };
    labProviderConfig: {
      isApprovalRequired: boolean;
      subdescription?: string;
    };

  };
  children?: (param) => void;
  btnLabel?: string;
  approvalStatus?: string;
  fetchLabs?: (subdescription?: string) => void;
  launchPresentation?: boolean;
  isVMRunnning?: boolean;
};

const LaunchLab: React.FC<LaunchLabProps> = ({
  lab,
  children,
  btnLabel,
  approvalStatus,
  fetchLabs,
  launchPresentation,
  isVMRunnning = true
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [usageTab, setUsageTab] = useState<string>("sales");
  const toggleUsageTab = (key) => setUsageTab(key);
  const [labLaunchModal, setLabLaunchModal] = useState<boolean>(false);
  const toggleLabLaunch = () => setLabLaunchModal(!labLaunchModal);
  const sidebar = useSelector((state: RootState) => state.sidebar);
  const user = useSelector((state: RootState) => state.user);


  const validationSchema = Yup.object({
    opportunityID: usageTab === 'sales' && oppIdValidationSchema(),
    other: Yup.string().when("usage", {
      is: "other",
      then: Yup.string().required("Please explain"),
    }),
    customerName: Yup.string().when("usage", {
      is: "Customer Demo - No Active Opportunity",
      then: Yup.string().required("Customer Name is required"),
    }),
    notes: Yup.string(),
  });

  const initialValues = {
    instance: lab.name || "",
    usage: "",
    opportunityID: "",
    notes: "",
    other: "",
    customerName: ""
  };

  const isApprovalRequired = lab?.isActive
    ? lab?.lab?.labProviderConfig?.isApprovalRequired
    : lab?.labProviderConfig?.isApprovalRequired;

  const getLaunchParams = async (labId, customerId?) => {
    const payload = customerId
      ? { lab: labId, customer_info: customerId }
      : { lab: labId };

    let response = await fetchLaunchParams(payload).catch((e) =>
      console.warn(e)
    );

    response = toCamelCaseKeys(response);

    if (response && !isEmpty(response)) {
      if (!isApprovalRequired) {
        launchConsole(PRESENTATION_ROUTE, history, response);
        sidebar.sidebarOpen && dispatch(setSidebar());
      } else {
        fetchLabs?.(
          isApprovalRequired ? lab?.labProviderConfig?.subdescription : ""
        );
      }
    }
  };

  const getPayload = (values) => ({
    name: values.instance,
    lab: lab.id,
    notes: values.notes,
    usage_type: usageTab.toUpperCase(),
    usage:
      usageTab === "sales"
        ? {
          opportunity_id: extractOppIdFromUrl(values.opportunityID),
          company: values.company,
          name: values.name,
          email: values.email,
        }
        : {
          type: values.other || values.usage,
          name: values.customerName || null
        },
  });

  const submitHandle = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (
      (values.opportunityID) ||
      values.other ||
      (values.usage && values.usage !== "other")
    ) {
      const payload = getPayload(values);
      if (!values.customerName) delete payload.usage.name
      let data = await postCustomerInfo(payload);
      data.id && data.lab && (await getLaunchParams(lab.id, data.id));
      toggleLabLaunch();
      resetForm();
      setSubmitting(false);
    }
  };

  const isApproved = isApprovalRequired && approvalStatus === "approved";
  const canLaunchConsole = !isApprovalRequired || isApproved;

  const prepareLabLaunch = () => {
    if (lab.isActive) {
      if (canLaunchConsole) {
        const labRoute = launchPresentation ? PRESENTATION_ROUTE : CONSOLE_ROUTE
        if (launchPresentation) {
          visitVraApprovedLab(lab?.gid)
        }
        sidebar.sidebarOpen && dispatch(setSidebar());
        launchConsole(labRoute, history, lab);
      }
    } else {
      if (!!lab.customerInfoRequired && (user.type === userTypesMap.corporate || user.type === userTypesMap.partner)) {
        toggleLabLaunch();
      } else {
        getLaunchParams(lab.id);
      }
    }
  };

  const label = lab.isActive
    ? approvalStatus === "approved"
      ? btnLabel
      : approvalStatus === "pending" ||
        approvalStatus === "provisioning" ||
        approvalStatus === null
        ? "Request Pending"
        : approvalStatus === "rejected"
          ? "Request Rejected"
          : btnLabel
    : btnLabel;

  return (
    <>
      {children ? (
        children(prepareLabLaunch)
      ) : (
        <button
          className={cx(
            {
              "disable-btn": (!canLaunchConsole && lab?.isActive) || (!isVMRunnning && btnLabel === 'POC Console'),
            },
            "card-footer-primary"
          )}
          onClick={prepareLabLaunch}
        >
          <span>{label}</span>
        </button>
      )}
      {labLaunchModal && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandle}
        >
          {(formik) => (
            <PureModal
              title="New Lab Instance"
              closeModal={toggleLabLaunch}
              showModal={labLaunchModal}
              primaryBtnLabel="Ok"
              primaryBtnAction={formik.handleSubmit}
              secondaryBtnLabel="Cancel"
              secondaryBtnAction={toggleLabLaunch}
              className={`lab-launch-modal ${(pathname.includes("/user/console") ||
                pathname.includes("/user/presentation")) &&
                "highest-ZIndex"
                }`}
            >
              <Form>
                <TextInput label="Instance name" name="instance" required />
                <Form.Label>
                  Usage Type
                  <RequiredFieldSymbol />
                </Form.Label>
                <div className="usage-tabs">
                  <Tabs
                    defaultActiveKey={usageTab}
                    id="usageType"
                    className="usage-type mb-3"
                    onSelect={toggleUsageTab}
                  >
                    <Tab eventKey="sales" title="Sales Opportunity">
                      <TextInput
                        label="Opportunity ID"
                        name="opportunityID"
                        placeholder="Enter Opportunity ID"
                        required
                      // onChange={(e) => {
                      //   const extractedOppId = extractOppIdFromUrl(e.target.value);
                      //   if (extractedOppId) {
                      //     // If URL, set the extracted Opp ID
                      //     formik.setFieldValue('opportunityID', extractedOppId);
                      //   } else {
                      //     // Otherwise, use the value as-is
                      //     formik.setFieldValue('opportunityID', e.target.value);
                      //   }
                      // }}
                      />
                    </Tab>
                    <Tab eventKey="others" title="Others">
                      <span className="select_warning">Note: Please do not use for customer demos with an active opportunity. If an opportunity exists, please choose "Sales Opportunity" and provide the opportunity ID.</span>
                      <RadioInput
                        name="usage"
                        className="usage-type-options"
                        options={[
                          {
                            label: "Personal Training",
                            id: "Personal Training",
                          },
                          {
                            label: "Trade show / event",
                            id: "Trade show / event",
                          },
                          {
                            label: "Lab QA / Testing",
                            id: "Lab QA / Testing",
                          },
                          {
                            label: "Customer Demo - No Active Opportunity",
                            id: "Customer Demo - No Active Opportunity",
                            component: () => (
                              <TextInput
                                label="Customer Name"
                                name="customerName"
                                placeholder="Enter Customer Name"
                                required
                                customClass={['ml-0', 'ml-5']}
                              />
                            ),

                          },
                          {
                            label: "Other - Please Explain",
                            id: "other",
                            component: () => (
                              <TextInput
                                name="other"
                                placeholder="Enter Text"
                                required
                                textarea
                                rows="3"
                              />
                            ),
                          },
                        ]}
                      />
                    </Tab>
                  </Tabs>
                </div>
                <TextInput
                  label="Notes"
                  name="notes"
                  placeholder="Optional Text"
                  textarea
                  rows="3"
                />
              </Form>
            </PureModal>
          )}
        </Formik>
      )}
    </>
  );
};

export default LaunchLab;
