import React from "react";
import ErrorPage from "../ErrorPage"
import noAccessIcon from 'src/assets/images/no-voucher-access.svg'

type ErrorPageProps = {
    title?: string;
    message: string;
    btnLabel: string;
    children?: React.ReactNode;
    hideGoBackButton?: boolean;
};
const AccessDenied: React.FC<ErrorPageProps> = ({
    title = 'Access Denied',
    message = 'You do not have permission to view this page. It seems that your account does not have the necessary privileges to access this content.',
    btnLabel,
    children,
    hideGoBackButton }) => {


    return <>
        <ErrorPage
            title={title}
            message={message}
            btnLabel={btnLabel}
            hideGoBackButton
        >
            <img
                height={200}
                src={noAccessIcon}
                alt="pure_logo"
                className="mt-5"
            />
        </ErrorPage>

    </>
}

export default AccessDenied;